import {getRestoreDatabaseUrl} from "../../helpers/apiUrlProvider";

export default async function restoreDatabase(jwt) {
    const response = await fetch(getRestoreDatabaseUrl(), {
        method: "POST",
        headers: { "Authorization": `Bearer ${jwt}` }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}