import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App.js';
import { AuthContextProvider } from "./context/AuthContext.js";
import { TournamentsContextProvider } from "./context/TournamentsContext.js";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <CookiesProvider>
            <AuthContextProvider>
                <TournamentsContextProvider>
                    <App/>
                </TournamentsContextProvider>
            </AuthContextProvider>
        </CookiesProvider>
    </React.StrictMode>
);
