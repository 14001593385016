export default function getUserTournament(user, tournament) {
    if (!user || !tournament)
        return null

    return user.tournaments.find(tournamentContainer => {
        // if (tournamentContainer.tournament){
        //     console.log(tournamentContainer.tournament._id, tournament._id)
        //     console.log(tournamentContainer.tournament._id === tournament._id)
        // }
        // console.log(tournamentContainer.tournament && tournamentContainer.tournament._id === tournament._id)

        return tournamentContainer.tournament &&
            tournamentContainer.tournament._id === tournament._id
    })
}