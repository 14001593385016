import { getMatchesUrl } from "../../helpers/apiUrlProvider";

export default async function changeMatchTournamentPhase(jwt, tournamentId, data) {
    const response = await fetch(getMatchesUrl(tournamentId), {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json))
}