import GuessDetail from "./GuessDetail.js";
import Collapsible from "../util/Collapsible.js";
import Container from "react-bootstrap/Container";

export default function GuessDetailList({ guesses, hideLatestGuess, correctScore, matchHasStarted }) {
    return <Container className="mt-2 ms-0 p-0">
        {guesses.length > 1 &&
            <Collapsible enableButton="Tipphistorie anzeigen" disableButton="Tipphistorie verbergen">
                {guesses.toReversed().map((guess, index) => {
                    if (hideLatestGuess && index === 0)
                        return ""
                    else
                        return <GuessDetail key={guess._id} guess={guess} isAuthUser={true} correctScore={correctScore} matchHasStarted={matchHasStarted}/>
                })}
            </Collapsible>
        }
    </Container>
}