import { createContext, useReducer, useEffect } from "react";
import { useCookies } from "react-cookie";

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { authUser: action.payload }
        case "LOGOUT":
            return { authUser: null }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        authUser: null
    })
    const [cookies] = useCookies(["authUser"])

    useEffect(() => {
        if (!cookies.authUser)
            return

        const authUser = cookies.authUser

        if (authUser)
            dispatch({ type: "LOGIN", payload: authUser })
    }, [cookies.authUser]);

    return <AuthContext.Provider value={{ ...state, dispatch }}>
        {children}
    </AuthContext.Provider>
}