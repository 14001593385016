import useAuthContext from "../../hooks/useAuthContext.js";
import Container from "react-bootstrap/Container";
import useTournamentsContext from "../../hooks/useTournamentsContext";
import ConditionalAlert from "../util/ConditionalAlert";
import Row from "react-bootstrap/Row";
import TournamentPhaseDropdown from "../tournament/TournamentPhaseDropdown";
import TeamDropdown from "../tournament/TeamDropdown";
import { useEffect, useState } from "react";
import TippspielSpinner from "../util/TippspielSpinner";
import Col from "react-bootstrap/Col";
import useUsers from "../../hooks/useUsers";
import ProgressBarForUser from "../util/ProgressBarForUser";
import useMatches from "../../hooks/useMatches";
import useMatchGuessesContext from "../../hooks/useMatchGuessesContext";
import Divider from "../util/Divider";
import MatchPreviewList from "../match/MatchPreviewList";
import { useCookies } from "react-cookie";
import getLatestGuess from "../../helpers/matchGuessToPoints";
import Chart from "./Chart";
import Collapsible from "../util/Collapsible";
import { HexColorPicker } from "react-colorful";
import updateUserColor from "../../requests/put/updateUserColor";
import Button from "react-bootstrap/Button";

export default function Stats() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()
    const { users, dispatchUsers } = useUsers()
    const [sortedUsers, setSortedUsers] = useState([])
    const [maxPoints, setMaxPoints] = useState(0)

    const { matches, error: matchesError, isLoading: matchesIsLoading } = useMatches()
    const [filteredMatches, setFilteredMatches] = useState([])

    const { matchGuesses } = useMatchGuessesContext()

    const [tournamentPhaseId, setTournamentPhaseId] = useState(JSON.parse(localStorage.getItem("filterTeamId")) ?? "")
    const [teamId, setTeamId] = useState(JSON.parse(localStorage.getItem("filterTournamentPhaseId")) ?? "")

    const [color, setColor] = useState(localStorage.getItem("color") ?? "#ff0000")

    const [cookies] = useCookies(["CookieConsent"])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    useEffect(() => {
        const filteredMatchIds = []
        setFilteredMatches(matches.filter(match => {
            if ((tournamentPhaseId ? match.tournamentPhase._id === tournamentPhaseId : true) &&
                (teamId ? ((match.homeTeam && match.homeTeam._id === teamId) || (match.awayTeam && match.awayTeam._id === teamId)) : true)) {
                filteredMatchIds.push(match._id)
                return true
            }
            return false
        }))

        let tmpMaxPoints = 0
        setSortedUsers(
            users.map(user => {
                user.guessCorrectnessCounts = {
                    correct: 0,
                    tendency: 0,
                    wrong: 0,
                    none: 0
                }
                user.currentTotalPoints = matchGuesses.filter(mG => {
                    return (user._id === mG.user._id && filteredMatchIds.includes(mG.match))
                })
                    .map(mG => {
                        const latestGuess = getLatestGuess(mG)
                        user.guessCorrectnessCounts[latestGuess.guessCorrectness] += 1
                        return latestGuess.pointsForGuess
                    })
                    .reduce((a, b) => a + b, 0)
                if (user.currentTotalPoints > tmpMaxPoints)
                    tmpMaxPoints = user.currentTotalPoints

                user.guessCorrectnessCounts.none = filteredMatchIds.length - user.guessCorrectnessCounts.correct - user.guessCorrectnessCounts.tendency - user.guessCorrectnessCounts.wrong
                if (filteredMatchIds.length > 0)
                    user.averagePoints = user.currentTotalPoints / filteredMatchIds.length
                else
                    user.averagePoints = 0
                return user
            })
                .sort((a, b) => b.currentTotalPoints - a.currentTotalPoints)
        )
        setMaxPoints(tmpMaxPoints > 0 ? tmpMaxPoints : null)
    }, [tournament, tournamentPhaseId, teamId, users, matches, matchGuesses, dispatchUsers])

    useEffect(() => {
        setError(matchesError)
    }, [matchesError]);

    useEffect(() => {
        setIsLoading(matchesIsLoading)
    }, [matchesIsLoading]);

    useEffect(() => {
        if (authUser && tournament) {
            // using local storage so the page does not get re-rendered on every cookie change
            setTeamId(JSON.parse(localStorage.getItem("filterTeamId")) ?? "")
            setTournamentPhaseId(JSON.parse(localStorage.getItem("filterTournamentPhaseId")) ?? "")
        }
    }, [authUser, tournament]);

    const handleTournamentPhaseDropdownChange = (e) => {
        setTournamentPhaseId(e.target.value)
        if (cookies.CookieConsent === "allow")
            localStorage.setItem("filterTournamentPhaseId", JSON.stringify(e.target.value))
    }

    const handleTeamDropdownChange = (teamId) => {
        setTeamId(teamId)
        if (cookies.CookieConsent === "allow")
            localStorage.setItem("filterTeamId", JSON.stringify(teamId))
    }

    const updateColor = (newColor) => {
        setColor(newColor)
        dispatchUsers({ type: "UPDATE_COLOR", payload: { _id: authUser._id, color: newColor } })
    }

    const persistUpdatedColor = () => {
        updateUserColor(authUser.token, authUser._id, color)
            .then(json => {
                dispatchUsers({ type: "UPDATE", payload: json })
                if (cookies.CookieConsent === "allow")
                    localStorage.setItem("color", color)
                setMessage("Farbe erfolgreich geändert.")
            })
            .catch(setError)
    }

    if (isLoading)
        return <TippspielSpinner />

    return <Container>
        <ConditionalAlert variant="success" message={message} onClose={() => setMessage("")} />
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />

        <Collapsible enableButton="Punktediagramm anzeigen" disableButton="Punktediagramm verbergen">
            <Chart matches={matches} matchGuesses={matchGuesses} users={sortedUsers} tournament={tournament} />
            <Container>
                <p className="text-center">(Namen anklicken zum ausblenden)</p>
                <Collapsible className="mt-1" enableButton="Eigene Farbe ändern" disableButton="Ändern abbrechen">
                    <HexColorPicker className="mt-1" color={color} onChange={updateColor}/>
                    <Button className="mt-1 ms-0" type="primary" onClick={persistUpdatedColor}>Änderung
                        speichern!</Button>
                </Collapsible>
            </Container>
        </Collapsible>
        <Divider/>

        <Row className="mb-2">
            <TournamentPhaseDropdown className="col-sm-6" onSelectCallback={handleTournamentPhaseDropdownChange}
                                     selected={tournamentPhaseId}/>
            <TeamDropdown className="col-sm-6" onSelectCallback={handleTeamDropdownChange} selected={teamId} onlyShowHighestTournamentPhase={false}/>
        </Row>
        <Divider />

        <Container>
            <Row className="mb-2">
                <Col className="col-4 col-md-3 col-lg-2 text-truncate"><b>Spieler</b></Col>
                <Col className="col-8 col-md-9"><b>Punkte (Punkte pro Spiel)</b></Col>
            </Row>
            {sortedUsers.length > 0 && sortedUsers.map(user =>
                <ProgressBarForUser key={user._id} user={user} min={0} max={maxPoints} now={user.currentTotalPoints} label={user.currentTotalPoints + " (" + user.averagePoints.toFixed(2) + ")"} />
            )}
        </Container>

        <Divider />
        <MatchPreviewList matches={filteredMatches} userId={null} />

    </Container>
}