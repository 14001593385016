import { useEffect, useState } from "react";
import useAuthContext from "../../hooks/useAuthContext.js";
import MatchGuessForm from "./MatchGuessForm.js";
import { dateIsInFuture, dateIsInPast } from "../../helpers/compareDates.js";
import Collapsible from "../util/Collapsible.js";
import GuessDetailList from "./GuessDetailList.js";
import GuessDetail from "./GuessDetail.js";
import Container from "react-bootstrap/Container";
import { fetchMatchGuessesByMatchAndUser } from "../../requests/get/fetchMatchGuesses";
import ConditionalAlert from "../util/ConditionalAlert";

export default function MatchGuessDetail({ match }) {
    const { authUser } = useAuthContext()

    const [guesses, setGuesses] = useState([])

    const [error, setError] = useState("")

    useEffect(() => {
        if (authUser) {
            fetchMatchGuessesByMatchAndUser(authUser.token, match.tournament, match._id, authUser._id)
                .then(json => setGuesses(json.length > 0 ? json[0].guesses : []))
                .catch(setError)
        }
    }, [authUser, match._id, match.tournament]);

    const useFormResults = (matchGuess) => {
        setGuesses(matchGuess.guesses)
    }

    return <Container className="p-0 ms-0">
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
        {!match.homeTeam && !match.awayTeam &&
            <p>Du kannst Tippen, sobald beide Teams feststehen.</p>
        }
        {guesses && guesses.length > 0 &&
            <GuessDetail guess={guesses[guesses.length - 1]} isAuthUser={true} isLatestGuess={true} correctScore={match.currentScore} matchHasStarted={dateIsInPast(match.startTime)} />
        }
        {guesses && guesses.length > 0 && dateIsInFuture(match.startTime) &&
            <Collapsible enableButton="Tipp ändern" disableButton="Tippen beenden" hideOnUpdate={useFormResults}>
                <MatchGuessForm match={match} onSubmitCallback={useFormResults}/>
            </Collapsible>
        }
        {!guesses && dateIsInFuture(match.startTime) &&
            <MatchGuessForm match={match} onSubmitCallback={useFormResults}/>
        }
        {guesses && guesses.length > 1 &&
            <GuessDetailList guesses={guesses} hideLatestGuess={true} correctScore={match.currentScore} matchHasStarted={dateIsInPast(match.startTime)} />
        }
        {(!guesses && dateIsInPast(match.startTime)) &&
            <p>Du hast leider verpasst, einen Tipp abzugeben.</p>
        }
    </Container>
}