import { useState } from "react";
import { useCookies } from "react-cookie";
import { getLoginUrl } from "../helpers/apiUrlProvider.js";
import useAuthContext from "./useAuthContext.js";

export default function useLogin() {
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const { dispatch } = useAuthContext()

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(["authUser", "CookieConsent"])

    const login = async (username, password) => {
        setIsLoading(true)
        setError(null)

        const response = await fetch(getLoginUrl(), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password })
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        else {
            // save user in cookie
            if (cookies.CookieConsent && cookies.CookieConsent === "allow") {
                setCookie("authUser", json, { path: "/", sameSite: "strict" })
                localStorage.setItem("color", json.color)
            }

            // update auth context
            dispatch({ type: "LOGIN", payload: json })

            setIsLoading(false)
            setError("")
        }
    }

    return { login, isLoading, error, setError }
}