import { useState } from "react";
import useAuthContext from "../../hooks/useAuthContext.js";
import useTournamentsContext from "../../hooks/useTournamentsContext.js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TippspielSpinner from "../util/TippspielSpinner";
import addTournamentToUser from "../../requests/post/addTournamentToUser";

export default function JoinTournamentForm({ onSubmitCallback, onErrorCallback }) {
    const { authUser } = useAuthContext()
    const { setTournament, dispatchTournaments } = useTournamentsContext()

    const [registerCode, setRegisterCode] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        addTournamentToUser(authUser.token, authUser._id, registerCode)
            .then(json => {
                setRegisterCode("")
                dispatchTournaments({ type: "ADD_TOURNAMENT", payload: json })
                setTournament(json)
                onSubmitCallback("Erfolgreich dem Turnier Beigetreten!")
            })
            .catch(onErrorCallback)
            .finally(() => setIsLoading(false))
    }

    if (isLoading)
        return <TippspielSpinner/>

    return <Form onSubmit={handleSubmit}>
        <h3>Anderem Turnier beitreten</h3>
        <Form.Group controlId="join-tournament-register-code">
            <Form.Label>Registrierungscode:</Form.Label>
            <Form.Control
                type="text"
                required
                minLength="9"
                maxLength="9"
                onChange={(e) => setRegisterCode(e.target.value)}
                value={registerCode}
            />
        </Form.Group>
        <Button className="mt-1" variant="primary" type="submit" disabled={isLoading}>Beitreten!</Button>
    </Form>
}