import useAuthContext from "../../hooks/useAuthContext.js";
import Container from "react-bootstrap/Container";
import TournamentPhaseDropdown from "../tournament/TournamentPhaseDropdown";
import TeamDropdown from "../tournament/TeamDropdown";
import Button from "react-bootstrap/Button";
import MatchPreviewList from "./MatchPreviewList";
import { useEffect, useState } from "react";
import useTournamentsContext from "../../hooks/useTournamentsContext";
import { dateIsInPast } from "../../helpers/compareDates";
import { useCookies } from "react-cookie";
import Row from "react-bootstrap/Row";
import useMatchGuessesContext from "../../hooks/useMatchGuessesContext";
import TippspielSpinner from "../util/TippspielSpinner";
import ConditionalAlert from "../util/ConditionalAlert";
import useMatches from "../../hooks/useMatches";
import joinErrors from "../util/joinErrors";

export default function FilterableMatchPreviewList({ userId, hideForeignGuesses }) {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const { matches, error: matchesError, isLoading: matchesIsLoading } = useMatches()
    const { matchGuesses, error: matchGuessesError, isLoading: matchesGuessesIsLoading } = useMatchGuessesContext()

    const [cookies] = useCookies(["CookieConsent"])

    const [tournamentPhaseId, setTournamentPhaseId] = useState(JSON.parse(localStorage.getItem("filterTeamId")) ?? "")
    const [teamId, setTeamId] = useState(JSON.parse(localStorage.getItem("filterTournamentPhaseId")) ?? "")
    const [hideAlreadyGuessed, setHideAlreadyGuessed] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")

    useEffect(() => {
        setError(joinErrors(matchesError, matchGuessesError))
    }, [matchesError, matchGuessesError]);

    useEffect(() => {
        setIsLoading(matchesIsLoading && matchesGuessesIsLoading)
    }, [matchesIsLoading, matchesGuessesIsLoading])

    useEffect(() => {
        if (authUser && tournament) {
            // using local storage so the page does not get re-rendered on every cookie change
            setTeamId(JSON.parse(localStorage.getItem("filterTeamId")) ?? "")
            setTournamentPhaseId(JSON.parse(localStorage.getItem("filterTournamentPhaseId")) ?? "")
        }
    }, [authUser, tournament]);

    const handleTournamentPhaseDropdownChange = (e) => {
        setTournamentPhaseId(e.target.value)
        if (cookies.CookieConsent === "allow")
            localStorage.setItem("filterTournamentPhaseId", JSON.stringify(e.target.value))
    }

    const handleTeamDropdownChange = (teamId) => {
        setTeamId(teamId)
        if (cookies.CookieConsent === "allow")
            localStorage.setItem("filterTeamId", JSON.stringify(teamId))
    }

    if (isLoading)
        return <TippspielSpinner/>

    if (!matches || matches.length === 0)
        return <p>{tournament ? "Dieses Turnier hat keine Spiele." : "Wähle ein Turnier aus."}</p>

    return <>
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
        <Row className="mb-2">
            <TournamentPhaseDropdown className="col-sm-6 col-md-4" onSelectCallback={handleTournamentPhaseDropdownChange}
                                     selected={tournamentPhaseId}/>
            <TeamDropdown className="col-sm-6 col-md-4" onSelectCallback={handleTeamDropdownChange} selected={teamId} onlyShowHighestTournamentPhase={false}/>
            <Container className="col-11 col-md-4 mt-2 mt-md-0" as={Button}
                       onClick={() => setHideAlreadyGuessed(!hideAlreadyGuessed)}>
                {hideAlreadyGuessed ?
                    "Alle Spiele anzeigen"
                    :
                    "Noch zu Tippen"
                }
            </Container>
        </Row>
        <MatchPreviewList matches={matches.filter(match => {
                const matchGuess = matchGuesses ? matchGuesses.find(mG => mG.match === match._id) : null

                return (
                    (tournamentPhaseId ? match.tournamentPhase._id === tournamentPhaseId : true) &&
                    (teamId ?
                        (match.homeTeam ? match.homeTeam._id : null) === teamId || (match.awayTeam ? match.awayTeam._id : null) === teamId
                        : true) &&
                    (hideForeignGuesses && authUser._id !== userId ? dateIsInPast(match.startTime) : true) &&
                    !(hideAlreadyGuessed && ((matchGuess && matchGuess.guesses && matchGuess.guesses.length > 0) || dateIsInPast(match.startTime) || match.homeTeam === null || match.awayTeam === null))
                )
            }
        )}
                          userId={userId}/>
    </>
}