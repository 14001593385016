import TournamentWinnerGuessDetail from "./TournamentWinnerGuessDetail.js";
import Container from "react-bootstrap/Container";

export default function TournamentWinnerGuessList({ tournamentWinnerGuesses, hideFirstItem }) {
    return <Container className="p-0 ms-0 mt-1">
        {tournamentWinnerGuesses && tournamentWinnerGuesses.length ?
            tournamentWinnerGuesses.toReversed().map((tournamentWinnerGuess, index) => {
                if (hideFirstItem && index === 0)
                    return ""
                return <TournamentWinnerGuessDetail key={tournamentWinnerGuess._id}
                                                    tournamentWinnerGuess={tournamentWinnerGuess}/>
            })
            :
            <p>Noch keinen Tipp abgegeben.</p>
        }
    </Container>
}