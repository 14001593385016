import useAuthContext from "../hooks/useAuthContext.js";
import Container from "react-bootstrap/Container";
import FilterableMatchPreviewList from "../components/match/FilterableMatchPreviewList";
import { MatchGuessesContextProvider } from "../context/MatchGuessesContext";

export default function Tournament() {
    const { authUser } = useAuthContext()

    return <Container>
        <MatchGuessesContextProvider userId={authUser._id}>
            <FilterableMatchPreviewList userId={authUser._id} hideForeignGuesses={false} />
        </MatchGuessesContextProvider>
    </Container>
}