import { useCookies } from "react-cookie";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import useAuthContext from "../../hooks/useAuthContext";
import useTournamentsContext from "../../hooks/useTournamentsContext";

export default function CookieConsentBanner() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [cookies, setCookie] = useCookies(["CookieConsent", "authUser", "tournamentId"])

    const onAgree = (e) => {
        e.preventDefault()

        let d = new Date()
        d.setDate(d.getDate() + 31)
        setCookie("CookieConsent", "allow", { path: "/", expires: d, sameSite: "strict" })

        setCookie("authUser", authUser, { path: "/", sameSite: "strict" })
        if (tournament)
            setCookie("tournamentId", tournament._id, { expires: d, path: "/", sameSite: "strict" })

        localStorage.setItem("color", authUser.color)
    }

    const onDisagree = (e) => {
        e.preventDefault()

        let d = new Date()
        d.setDate(d.getDate() + 31)
        setCookie("CookieConsent", "disallow", { path: "/", expires: d, sameSite: "strict" })
    }

    return !cookies.CookieConsent &&
        <Container className="cookie-consent-banner">
            <h4>Cookies</h4>
            <pre>
                Cookies werden verwendet, um die Anmeldedaten im Browser zu speichern, bis dieser geschlossen wird.<br/>
                Dadurch muss man sich nicht jedes mal neu anmelden, wenn man den Tab schließt und neu öffnet.<br/>
                Cookies werden verwendet, um das zuletzt ausgewählte Turnier im Browser zu speichern.
            </pre>
            <Button className="border border-1 border-primary" variant="outline-success"
                    onClick={onAgree}>Annehmen</Button>
            <Button className="mx-1" variant="outline-danger" onClick={onDisagree}>Ablehnen</Button>
        </Container>
}