import { useContext } from "react";
import { MatchGuessesContext } from "../context/MatchGuessesContext";

export default function useMatchGuessesContext() {
    const context = useContext(MatchGuessesContext)

    if (!context)
        throw Error("useMatchGuessesContext must be used inside a MatchGuessesContextProvider")

    return context
}