import MatchPreview from "./MatchPreview.js";
import Container from "react-bootstrap/Container";
import MatchDivider from "../MatchDivider";
import Divider from "../util/Divider";
import useMatchGuessesContext from "../../hooks/useMatchGuessesContext";
import MatchPreviewAllGuesses from "./MatchPreviewAllGuesses";

export default function MatchPreviewList({ matches, userId }) {
    const { matchGuesses } = useMatchGuessesContext()

    let currentTournamentPhase = ""

    return <Container className="gap-0 row-gap-2 p-0">
        {matches.length > 0 ?
            matches.map(match => {
                let data
                if (!userId)
                    data = matchGuesses ? matchGuesses.filter(mG => mG.match === match._id) : []
                else
                    data = matchGuesses ? matchGuesses.find(mG => mG.match === match._id && mG.user._id === userId) : null

                if (currentTournamentPhase !== match.tournamentPhase.name) {
                    currentTournamentPhase = match.tournamentPhase.name

                    return <Container key={match._id} className="p-0">
                        <Divider />
                        <p className="fs-4">{match.tournamentPhase.name}</p>
                        {!userId ?
                            <MatchPreviewAllGuesses match={match} userId={userId} matchGuesses={data} />
                            :
                            <MatchPreview match={match} userId={userId} matchGuess={data} />
                        }
                    </Container>
                }

                return <Container key={match._id} className="p-0">
                    <MatchDivider/>
                    {!userId ?
                        <MatchPreviewAllGuesses match={match} matchGuesses={data} />
                        :
                        <MatchPreview match={match} userId={userId} matchGuess={data} />
                    }
                </Container>
            })
            :
            <Container>
                <Divider />
                <p>Kein Spiel entspricht dem Filter.</p>
            </Container>
        }
    </Container>
}