import { useEffect, useState } from "react";
import useAuthContext from "../hooks/useAuthContext.js";
import useTournamentsContext from "../hooks/useTournamentsContext.js";
import Collapsible from "../components/util/Collapsible.js";
import TournamentPhaseForm from "../components/tournament/TournamentPhaseForm.js";
import TournamentPhaseJoinForm from "../components/tournament/TournamentPhaseJoinForm.js";
import TeamDropdown from "../components/tournament/TeamDropdown.js";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TippspielSpinner from "../components/util/TippspielSpinner";
import Divider from "../components/util/Divider";
import ConditionalAlert from "../components/util/ConditionalAlert";
import updateTournament from "../requests/put/updateTournament";
import deleteTournament from "../requests/delete/deleteTournament";
import recalculateTournament from "../requests/post/recalculateTournament";
import forceUpdateMatchData from "../requests/post/forceUpdateMatchData";
import backupDatabase from "../requests/get/backupDatabase";
import restoreDatabase from "../requests/post/restoreDatabase";
import useTournamentPhases from "../hooks/useTournamentPhases";

export default function EditTournament() {
    const { authUser } = useAuthContext()
    const { tournament, setTournament, dispatchTournaments } = useTournamentsContext()
    const { tournamentPhases, setTournamentPhases, error: tournamentPhasesError, isLoading: tournamentPhasesIsLoading } = useTournamentPhases()

    const [name, setName] = useState("")
    const [registerCode, setRegisterCode] = useState("")
    const [maxUsers, setMaxUsers] = useState("")
    const [tournamentWinner, setTournamentWinner] = useState("")

    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!tournament)
            return

        setName(tournament.name)
        setRegisterCode(tournament.authInfo.registerCode)
        setMaxUsers(tournament.authInfo.maxUsers)
        setTournamentWinner(tournament.tournamentWinner ? tournament.tournamentWinner._id : "")
    }, [tournament]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const tournamentChanges = {
            name,
            tournamentWinner,
            authInfo: {
                maxUsers,
                registerCode
            }
        }

        updateTournament(authUser.token, tournament._id, tournamentChanges)
            .then(json => {
                dispatchTournaments({ type: "UPDATE_TOURNAMENT", payload: json })
                setTournament(json)
                setMessage("Das Turnier wurde erfolgreich geändert.")
                setError("")
            })
            .catch(errorMessage => {
                setError(errorMessage)
                setMessage("")
            })
            .finally(() => setIsLoading(false))
    }

    const updateTournamentPhases = (updatedTournamentPhase) => {
        setTournamentPhases(tournamentPhases.map(tournamentPhase => {
            if (tournamentPhase._id !== updatedTournamentPhase._id)
                return tournamentPhase
            else
                return updatedTournamentPhase
        }))
    }

    const updateTournamentPhasesOnJoin = ({ newTournamentPhase, oldTournamentPhaseIds }) => {
        setTournamentPhases([
            newTournamentPhase,
            ...tournamentPhases.filter(tournamentPhase => !oldTournamentPhaseIds.includes(tournamentPhase._id))
        ].sort((a, b) => a.order - b.order))
    }

    const handleDeleteTournament = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        deleteTournament(authUser.token, tournament._id)
            .then(json => {
                dispatchTournaments({ type: "REMOVE_TOURNAMENT", payload: json })
                setTournament("")
                setMessage("Das Turnier wurde gelöscht.")
                setError("")
            })
            .catch(errorMessage => {
                setError(errorMessage)
                setMessage("")
            })
            .finally(() => setIsLoading(false))
    }

    const handleRecalculateScores = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        recalculateTournament(authUser.token, tournament._id)
            .then(() => {
                setMessage("Das Turnier wurde neu berechnet.")
                setError("")
            })
            .catch(errorMessage => {
                setError(errorMessage)
                setMessage("")
            })
            .finally(() => setIsLoading(false))
    }

    const handleForceUpdateMatchData = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        forceUpdateMatchData(authUser.token, tournament._id)
            .then(() => {
                setMessage("Die Daten für die Spiele im Turnier wurden aktualisiert.")
                setError("")
            })
            .catch(errorMessage => {
                setError(errorMessage)
                setMessage("")
            })
            .finally(() => setIsLoading(false))
    }

    const handleBackupDatabase = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        await backupDatabase(authUser.token)
            .then(msg => {setMessage(msg);setError("")})
            .catch(err => {setMessage("");setError(err)})
            .finally(() => setIsLoading(false))
    }

    const handleRestoreDatabase = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        await restoreDatabase(authUser.token)
            .then(setMessage)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    if (isLoading)
        return <TippspielSpinner/>

    if (!tournament)
        return <Container>Wähle ein Turnier aus.</Container>

    return <>
        <ConditionalAlert variant="success" message={message} onClose={() => setMessage("")} />
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
        <Form onSubmit={handleBackupDatabase}>
            <h3>Datenbankbackup erstellen</h3>
            <Button variant="primary" type="submit" disabled={isLoading}>Datenbankbackup erstellen</Button>
        </Form>
        <Form onSubmit={handleRestoreDatabase}>
            <h3>Datenbankbackup hochladen</h3>
            <Button variant="primary" type="submit" disabled={isLoading}>Datenbankbackup hochladen</Button>
        </Form>

        <Form onSubmit={handleRecalculateScores}>
            <h3>Punkte neu berechnen</h3>
            <Button variant="primary" type="submit" disabled={isLoading}>Punkte neu berechnen</Button>
        </Form>
        <Form onSubmit={handleForceUpdateMatchData}>
            <h3>Spieldatenaktualisierung erzwingen</h3>
            <Button variant="primary" type="submit" disabled={isLoading}>Spieldatenaktualisierung erzwingen</Button>
        </Form>
        <Divider/>
        <Form onSubmit={handleSubmit}>
            <h3>Turnier bearbeiten</h3>
            <Form.Group controlId="edit-tournament-name">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                    type="text"
                    required
                    onChange={(e) => {
                        setMessage("");
                        setName(e.target.value)
                    }}
                    value={name}
                />
            </Form.Group>
            <Form.Group controlId="edit-tournament-registercode">
                <Form.Label>Registrierungscode:</Form.Label>
                <Form.Control
                    type="text"
                    minLength="9"
                    maxLength="9"
                    required
                    onChange={(e) => {
                        setMessage("");
                        setRegisterCode(e.target.value)
                    }}
                    value={registerCode}
                />
            </Form.Group>
            <Form.Group controlId="edit-tournament-max-users">
                <Form.Label>Maximale Teilnehmerzahl:</Form.Label>
                <Form.Control
                    type="number"
                    required
                    min="0"
                    max="100"
                    onChange={(e) => {
                        setMessage("");
                        setMaxUsers(e.target.value)
                    }}
                    value={maxUsers}
                />
            </Form.Group>
            <Form.Group controlId="edit-tournament-max-users">
                <Form.Label>Turniersieger:</Form.Label>
                <TeamDropdown className="ms-0 p-0"
                    emptySelectionString="Noch kein Turniersieger"
                    selected={tournamentWinner}
                    onSelectCallback={setTournamentWinner}/>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-2" disabled={isLoading}>Änderungen speichern!</Button>
        </Form>
        <Divider/>
        <Container>
            {tournamentPhases && tournamentPhases.length > 0 ?
                <>
                    <Container>
                        <h3>Einzelne Turnierphasen bearbeiten</h3>
                        {tournamentPhases.map(tournamentPhase =>
                            <Collapsible key={tournamentPhase._id}
                                         enableButton={tournamentPhase.name + " bearbeiten"}
                                         disableButton={tournamentPhase.name + " nicht weiter bearbeiten"}
                                         hideOnUpdate={tournamentPhase}>
                                <TournamentPhaseForm tournamentPhase={tournamentPhase}
                                                     onSubmitCallback={updateTournamentPhases}/>
                            </Collapsible>
                        )}
                    </Container>
                    <Divider/>
                    {tournamentPhases.length > 1 &&
                        <Container>
                            <h3>Turnierphasen zusammenfassen</h3>
                            <Collapsible
                                enableButton="Turnierphasen zusammenfassen"
                                disableButton="Turnierphasen nicht weiter zusammenfassen"
                                hideOnUpdate={updateTournamentPhasesOnJoin}>
                                <TournamentPhaseJoinForm tournamentPhases={tournamentPhases}
                                                         onSubmitCallback={updateTournamentPhasesOnJoin}/>
                            </Collapsible>
                        </Container>
                    }
                </>
                :
                <p>Dieses Turnier hat keine Turnierphasen.</p>
            }
        </Container>
        <Divider/>

        <Form onSubmit={handleDeleteTournament}>
            <h3>Turnier löschen</h3>
            <Button variant="primary" type="submit" disabled={isLoading}>Turnier löschen</Button>
        </Form>
    </>
}