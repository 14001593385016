import UserPreview from "./UserPreview.js";
import Container from "react-bootstrap/Container";
import useTournamentsContext from "../../hooks/useTournamentsContext";
import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";


export default function UserPreviewList({ users }) {
    const { tournament } = useTournamentsContext()

    const [sortedUsers, setSortedUsers] = useState([])
    const [maxPoints, setMaxPoints] = useState(0)

    useEffect(() => {
        let tmpMaxPoints = 0
        setSortedUsers(
            users.sort((a, b) => {
                const tournamentA = a.tournaments.find(userTournament => userTournament.tournament._id === tournament._id)
                const tournamentB = b.tournaments.find(userTournament => userTournament.tournament._id === tournament._id)
                if (!tournamentA || !tournamentB)
                    return 0
                if (tournamentA.totalPoints > tmpMaxPoints)
                    tmpMaxPoints = tournamentA.totalPoints
                if (tournamentB.totalPoints > tmpMaxPoints)
                    tmpMaxPoints = tournamentB.totalPoints
                return tournamentB.totalPoints - tournamentA.totalPoints
            })
        )
        setMaxPoints(tmpMaxPoints)
    }, [users, tournament]);

    return <Container className="p-0 ms-0">
        <Row className="column-gap-0 mb-2 text-body-emphasis">
            <Container className="col-4 col-sm-3 col-md-2 col-xl-1"><b>Teilnehmer</b></Container>
            <Container className="col-5 col-sm-3 col-md-2 col-xl-2"><b>Turniersieger (Punkte)</b></Container>
            <Container className="col-3 col-sm-6 col-md-8 col-xl-9"><b>Punkte</b></Container>
        </Row>
        {users ?
            sortedUsers.map((user) =>
                <UserPreview key={user.username} user={user} maxPoints={maxPoints} />
            )
            :
            <p>Keine Nutzer in der Liste.</p>
        }
    </Container>
}