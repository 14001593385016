import { useState } from "react";
import useAuthContext from "../hooks/useAuthContext.js";
import useTournamentsContext from "../hooks/useTournamentsContext.js";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ConditionalAlert from "../components/util/ConditionalAlert";
import addTournamentToUser from "../requests/post/addTournamentToUser";
import fetchTournament from "../requests/get/fetchTournament";
import createTournament from "../requests/post/createTournament";

export default function NewTournament() {
    const { authUser } = useAuthContext()
    const { setTournament, dispatchTournaments } = useTournamentsContext()

    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [maxUsers, setMaxUsers] = useState("10")
    const [allMatchesPath, setAllMatchesPath] = useState("")
    const [singleMatchPath, setSingleMatchPath] = useState("")

    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const joinTournament = async (tournament) => {
        addTournamentToUser(authUser.token, authUser._id, tournament.authInfo.registerCode)
            .then(() => {
                setTournament(tournament)
                setIsLoading(false)
                navigate("/")
            })
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    const fetchNewTournament = async (tournamentId) => {
        fetchTournament(authUser.token, tournamentId)
            .then(async json => {
                dispatchTournaments({ type: "ADD_TOURNAMENT", payload: json })
                await joinTournament(json)
            })
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const tournament = {
            name: name,
            authInfo: {
                maxUsers: maxUsers
            },
            apiInfo: {
                paths: {
                    allMatches: allMatchesPath,
                    singleMatch: singleMatchPath
                }
            }
        }

        createTournament(authUser.token, tournament)
            .then(async json => {
                setName("")
                setAllMatchesPath("")
                setSingleMatchPath("")

                setMessage("Das Turnier wurde erfolgreich angelegt.")
                setError("")
                await fetchNewTournament(json._id)
            })
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    return <Form onSubmit={handleSubmit}>
        <h3>Neues Turnier anlegen</h3>
        <Form.Group controlId="new-tournament-name">
            <Form.Label>Name:</Form.Label>
            <Form.Control
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
            />
        </Form.Group>
        <Form.Group controlId="new-tournament-max-users">
            <Form.Label>Maximale Teilnehmerzahl:</Form.Label>
            <Form.Control
                type="number"
                min="0"
                max="100"
                required
                onChange={(e) => setMaxUsers(e.target.value)}
                value={maxUsers}
            />
        </Form.Group>
        <Form.Group controlId="new-tournament-all-matches-path">
            <Form.Label>API-Endpunkt mit allen Spielen:</Form.Label>
            <Form.Control
                type="text"
                required
                onChange={(e) => setAllMatchesPath(e.target.value)}
                value={allMatchesPath}
            />
        </Form.Group>
        <Form.Group controlId="new-tournament-all-matches-path">
            <Form.Label>API-Endpunkt für ein einzelnes Spiel (ohne ID):</Form.Label>
            <Form.Control
                type="text"
                required
                onChange={(e) => setSingleMatchPath(e.target.value)}
                value={singleMatchPath}
            />
        </Form.Group>
        <Button className="mt-1" variant="primary" type="submit" disabled={isLoading}>Turnier anlegen!</Button>
        <ConditionalAlert variant="success" message={message} onClose={() => setMessage("")} />
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
    </Form>
}