import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

export default function Collapsible({
                                        className,
                                        children,
                                        initialState,
                                        enableButton,
                                        disableButton,
                                        hideOnUpdate,
                                        showOnUpdate
                                    }) {
    const [visible, setVisible] = useState(initialState ?? false)

    useEffect(() => {
        setVisible(false)
    }, [hideOnUpdate]);

    useEffect(() => {
        setVisible(true)
    }, [showOnUpdate]);

    useEffect(() => {
        // will be the last Effect before rendering; other effects will be called again if the props change
        setVisible(initialState)
    }, [initialState]);

    return <Container className={"p-0 ms-0 " + (className ?? "")}>
        <Button variant="outline-primary" onClick={() => setVisible(!visible)}>
            {visible ? disableButton : enableButton}
        </Button>
        {visible && children}
    </Container>
}