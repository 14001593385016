import { useCookies } from "react-cookie";
import useAuthContext from "./useAuthContext.js";
import useTournamentsContext from "./useTournamentsContext.js";

export default function useLogout() {
    const { dispatch } = useAuthContext()
    const { setTournament } = useTournamentsContext()

    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(["authUser"])

    const logout = () => {
        // remove user from storage
        removeCookie("authUser", { path: "/" })
        // dispatch logout action
        dispatch({ type: "LOGOUT" })
        // unset tournament
        setTournament(null)
    }

    return { logout }
}