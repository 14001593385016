import useAuthContext from "../../hooks/useAuthContext.js";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import useTournamentPhases from "../../hooks/useTournamentPhases";

export default function TournamentPhaseDropdown({ className, emptySelectionString, selected, onSelectCallback }) {
    const { authUser } = useAuthContext()
    const { tournamentPhases } = useTournamentPhases()

    return <Container className={className ?? ""}>
        <Form.Select value={selected} onChange={onSelectCallback}>
            <option value="">{authUser ? (emptySelectionString ?? "Alle Spiele") : "Du bist nicht angemeldet"}</option>
            {tournamentPhases.length > 0 && tournamentPhases.map(tournamentPhase =>
                <option key={tournamentPhase._id} value={tournamentPhase._id}>
                    {tournamentPhase.name}
                </option>
            )}
        </Form.Select>
    </Container>
}