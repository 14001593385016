import Alert from "react-bootstrap/Alert";
import { CloseButton } from "react-bootstrap";

/**
 * Will only show up, if message is truthy
 * @param className
 * @param variant one of primary, light, danger, success, secondary, warning, dark, info
 * @param message
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const ConditionalAlert = ({ className, variant, message, onClose }) => {
    return message && <Alert className={className ? className : ""} variant={variant}>
            {message.toString()}
            <CloseButton className="float-end" onClick={() => onClose instanceof Function ? onClose() : {}} />
        </Alert>
}
export default ConditionalAlert
