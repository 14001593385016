import { BACKEND_DOMAIN, BACKEND_URI } from "../config.js";

const baseUrl = BACKEND_DOMAIN ?? BACKEND_URI

// general
export const getBackupDatabaseUrl = () => baseUrl + "/backupDatabase"
export const getRestoreDatabaseUrl = () => baseUrl + "/restoreDatabase"

// Tournament
export const getTournamentsUrl = () => baseUrl + "/tournaments"
export const getTournamentByIdUrl = (tournamentId) => getTournamentsUrl() + "/" + tournamentId
export const getTournamentByIdRecalculateUrl = (tournamentId) => getTournamentByIdUrl(tournamentId) + "/recalculate"
export const getTournamentByIdForceUpdateMatchesUrl = (tournamentId) => getTournamentByIdUrl(tournamentId) + "/updateMatchData"

// TournamentPhase
export const getTournamentPhasesUrl = (tournamentId) => getTournamentByIdUrl(tournamentId) + "/tournamentPhases"
export const getTournamentPhaseByIdUrl = (tournamentId, tournamentPhaseId) => getTournamentPhasesUrl(tournamentId) + "/" + tournamentPhaseId

// Match
export const getMatchesUrl = (tournamentId) => getTournamentByIdUrl(tournamentId) + "/matches"
export const getMatchByIdUrl = (tournamentId, matchId) => getMatchesUrl(tournamentId) + "/" + matchId

// MatchGuess
export const getMatchGuessesUrl = (tournamentId) => getTournamentByIdUrl(tournamentId) + "/matchGuesses"
export const getMatchGuessByUserIdUrl = (tournamentId, userId) => getMatchGuessesUrl(tournamentId) + "?userId=" + userId
export const getMatchGuessByMatchIdUrl = (tournamentId, matchId) => getMatchGuessesUrl(tournamentId) + "?matchId=" + matchId
export const getMatchGuessByUserIdAndMatchIdUrl = (tournamentId, userId, matchId) => getMatchGuessesUrl(tournamentId) + "?userId=" + userId + "&matchId=" + matchId

// Team
export const getTeamsUrl = (tournamentId) => getTournamentByIdUrl(tournamentId) + "/teams"
export const getTeamByIdUrl = (tournamentId, teamId) => getTeamsUrl(tournamentId) + "/" + teamId

// User
export const getUsersUrl = () => baseUrl + "/users"
export const getUserByIdUrl = (userId) => getUsersUrl() + "/" + userId
export const getUserColorByIdUrl = (userId) => getUsersUrl() + "/" + userId + "/color"
export const getUserByUsernameUrl = (username) => getUsersUrl() + "/" + username
export const getUserByIdTournamentsUrl = (userId) => getUserByIdUrl(userId) + "/tournaments"
export const getUserTournamentWinnerGuessUrl = (userId, tournamentId) => getUserByIdTournamentsUrl(userId) + "/" + tournamentId + "/tournamentWinnerGuesses"

export const getLoginUrl = () => getUsersUrl() + "/login"
export const getRegisterUrl = () => getUsersUrl() + "/register"
export const getResetPasswordUrl = () => getUsersUrl() + "/resetPassword"
export const getUsersByTournamentIdUrl = (tournamentId) => getUsersUrl() + "?tournamentId=" + tournamentId
