import { useState } from "react";
import useLogin from "../hooks/useLogin.js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ConditionalAlert from "../components/util/ConditionalAlert";

export default function Login() {
    const { login, error, isLoading, setError } = useLogin()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async (event) => {
        // prevent refreshing the page
        event.preventDefault()
        await login(username, password)
    }

    return <Form onSubmit={handleSubmit}>
        <h3>Anmelden</h3>
        <Form.Group controlId="login-username">
            <Form.Label>Benutzername:</Form.Label>
            <Form.Control
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
            />
        </Form.Group>

        <Form.Group controlId="login-password">
            <Form.Label>Passwort:</Form.Label>
            <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
        </Form.Group>
        <Button className="mt-1" variant="primary" type="submit" disabled={isLoading}>Anmelden</Button>
        <ConditionalAlert className="mt-1" variant="danger" message={error} onClose={() => setError("")}/>
    </Form>
}