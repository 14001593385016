import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import useAuthContext from "../../hooks/useAuthContext";
import { getResetPasswordUrl } from "../../helpers/apiUrlProvider";

export default function UserResetPasswordForm({ onSubmitCallback, onErrorCallback }) {
    const { authUser } = useAuthContext()

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()


        const response = await fetch(getResetPasswordUrl(), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authUser.token}`
            },
            body: JSON.stringify({
                username: authUser.username,
                password,
                confirmPassword
            })
        })
        const json = await response.json()

        if (!response.ok) {
            onErrorCallback(json.error)
        }
        else {
            onErrorCallback("")
            onSubmitCallback(json)
        }
    }

    return <Form onSubmit={handleSubmit}>
        <h3>Passwort ändern</h3>
        <Form.Group controlId="reset-password-password">
            <Form.Label>Neues Passwort:</Form.Label>
            <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
        </Form.Group>

        <Form.Group controlId="reset-password-confirm-password">
            <Form.Label>Neues Passwort bestätigen:</Form.Label>
            <Form.Control
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
            />
        </Form.Group>

        <Button variant="primary" type="submit">Passwort ändern</Button>
    </Form>
}