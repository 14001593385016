import { getUserByIdTournamentsUrl } from "../../helpers/apiUrlProvider";

export default async function addTournamentToUser(jwt, userId, registerCode) {
    const response = await fetch(getUserByIdTournamentsUrl(userId), {
        method: "POST",
        body: JSON.stringify({
            registerCode
        }),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}
