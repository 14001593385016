import { Link } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext.js";
import { dateIsInFuture, dateIsInPast } from "../../helpers/compareDates.js";
import formatDate from "../../helpers/formatDate";
import { getFormattedScores } from "../../helpers/getFormattedScores";
import getLatestGuess from "../../helpers/matchGuessToPoints";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ShortGuessDetail from "../matchGuess/ShortGuessDetail";

export default function MatchPreviewAllGuesses({ match, matchGuesses }) {
    // todo: take in matchGuesses
    const { authUser } = useAuthContext()

    return <Row className="row p-0 ms-0 justify-content-evenly">
        <Row>
            <Link className="fs-5" to="/spiel" state={{ match: match }}>
                {match.homeTeam ? match.homeTeam.name : match.homeTeamPlaceholder} - {match.awayTeam ? match.awayTeam.name : match.awayTeamPlaceholder}
            </Link>
            {dateIsInFuture(match.startTime) ?
                <p className="fs-5">am {formatDate(match.startTime)}</p>
                :
                <p className="fs-5">{getFormattedScores(match.currentScore, match.scores)}</p>
            }
        </Row>
        <Row className="p-0">
            {dateIsInPast(match.startTime) && matchGuesses.length > 0 && matchGuesses.map(mG => {
                const latestGuess = getLatestGuess(mG)
                if (latestGuess)
                    return <Col className="col-4 col-sm-3 col-lg-2 col-xl-1" key={mG._id}>
                        <ShortGuessDetail guess={latestGuess} username={mG.user.username} isLatestGuess={true} isAuthUser={mG.user._id === authUser._id} matchHasStarted={dateIsInPast(match.startTime)} correctScore={match.currentScore} />
                    </Col>
                return <></>
                })
            }
        </Row>
    </Row>
}