import { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Container from "react-bootstrap/Container";
import formatDate from "../../helpers/formatDate";
import getLatestGuess from "../../helpers/matchGuessToPoints";

export default function Chart({ matches, matchGuesses, users, tournament }) {
    const [chartData, setChartData] = useState([])

    const [hidden, setHidden] = useState({})

    useEffect(() => {
        if (matches.length === 0 || matchGuesses.length === 0 || users.length === 0)
            return

        const newData = []
        let currentDate = formatDate(matches[0].startTime).split("2024")[0]
        const currentDataPoint = {
            name: currentDate
        }

        const initialHidden = {}
        for (const user of users) {
            currentDataPoint[user.username] = 0
            initialHidden[user.username] = false
        }
        setHidden(initialHidden)

        let lastDataPointAdded = false
        matches.forEach(match => {
            if (Date.parse(match.startTime) >= Date.now()) {
                if (!lastDataPointAdded) {
                    newData.push(JSON.parse(JSON.stringify(currentDataPoint)))
                    lastDataPointAdded = true
                }
                return
            }

            if (currentDataPoint.name !== formatDate(match.startTime).split("2024")[0]) {
                newData.push(JSON.parse(JSON.stringify(currentDataPoint)))
                currentDataPoint.name = formatDate(match.startTime).split("2024")[0]
            }

            for (const user of users) {
                const matchGuess = matchGuesses.find(mG =>  mG.match === match._id && mG.user._id === user._id)
                const latestGuess = getLatestGuess(matchGuess)
                currentDataPoint[user.username] += latestGuess ? latestGuess.pointsForGuess : 0
            }
            // console.log(currentDataPoint)
        })

        if (tournament.tournamentWinner) {
            users.forEach(user => {
                const userTournament = user.tournaments.find(userTournament => userTournament.tournament._id === tournament._id)
                const tournamentWinnerGuess = userTournament.tournamentWinnerGuesses[userTournament.tournamentWinnerGuesses.length - 1]
                currentDataPoint[user.username] += tournamentWinnerGuess.pointsForGuess ?? 0
            })
            newData.push(JSON.parse(JSON.stringify(currentDataPoint)))
        }

        setChartData(newData)
    }, [matches, matchGuesses, users]);

    return <Container className="chart-container mb-1 mt-2 ms-0 p-0">
        <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={300} data={chartData}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis type="number"/>
                <Tooltip itemSorter={item => -item.value}/>
                <Legend onClick={payload => setHidden({ ...hidden, [payload.dataKey]: !hidden[payload.dataKey] })}/>
                {users.length > 0 && users.map(user => {
                    return <Line key={user._id} type="monotone" dataKey={user.username} stroke={user.color ?? "#ff0000"} hide={hidden[user.username]}/>
                })}
            </LineChart>
        </ResponsiveContainer>
    </Container>
}