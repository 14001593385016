import useAuthContext from "../../hooks/useAuthContext.js";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import useTeams from "../../hooks/useTeams";
import { useEffect, useState } from "react";
import useTournamentPhases from "../../hooks/useTournamentPhases";

export default function TeamDropdown({ className, emptySelectionString, onlyShowHighestTournamentPhase, selected, allowRandomOption, onSelectCallback }) {
    const { authUser } = useAuthContext()
    const { teams} = useTeams()
    const { tournamentPhases } = useTournamentPhases()

    const [filteredTeams, setFilteredTeams] = useState(teams)

    useEffect(() => {
        if (onlyShowHighestTournamentPhase) {
            const currentTournamentPhase = tournamentPhases.find(tP => tP.currentlyActive === true)
            if (currentTournamentPhase)
                setFilteredTeams(teams.filter(team =>  team.highestTournamentPhaseOrder >= currentTournamentPhase.order))
            else
                setFilteredTeams([])
        }
        else {
            setFilteredTeams(teams)
        }
    }, [teams, tournamentPhases, onlyShowHighestTournamentPhase]);

    const prepareSelectCallback = (e) => {
        let teamId
        if (e.target.value === "random")
            teamId = filteredTeams[Math.floor(Math.random() * filteredTeams.length)]._id
        else
            teamId = e.target.value

        onSelectCallback(teamId)
    }

    return <Container className={className ?? ""}>
        <Form.Select value={selected} onChange={prepareSelectCallback}>
            <option value="">{authUser ? (emptySelectionString ?? "Alle Teams") : "Du bist nicht angemeldet"}</option>
            {allowRandomOption && filteredTeams.length > 0 && <option value="random">Zufälliges Team</option>}
            {filteredTeams.length > 0 && filteredTeams.map(team =>
                <option key={team._id} value={team._id}>
                    {team.name}
                </option>
            )}
        </Form.Select>
    </Container>
}