import { getUserByIdUrl, getUserByUsernameUrl } from "../../helpers/apiUrlProvider";

export async function fetchUserByUsername(jwt, username) {
    const response = await fetch(getUserByUsernameUrl(username), {
        headers: { "Authorization": `Bearer ${jwt}` }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}

export async function fetchUserById(jwt, userId) {
    const response = await fetch(getUserByIdUrl(userId), {
        headers: { "Authorization": `Bearer ${jwt}` }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}