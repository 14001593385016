import { createContext, useEffect, useReducer, useState } from "react";
import { useCookies } from "react-cookie";
import fetchTournaments from "../requests/get/fetchTournaments";
import useAuthContext from "../hooks/useAuthContext";

export const TournamentsContext = createContext()

const tournamentsReducer = (state, action) => {
    switch (action.type) {
        case "SET_TOURNAMENTS":
            return { tournaments: action.payload }
        case "ADD_TOURNAMENT":
            return { tournaments: [action.payload, ...state.tournaments] }
        case "UPDATE_TOURNAMENT":
            return {
                tournaments: state.tournaments.map(tournament => {
                    if (tournament._id !== action.payload._id)
                        return tournament
                    else
                        return action.payload
                })
            }
        case "REMOVE_TOURNAMENT":
            return { tournaments: state.tournaments.filter(tournament => tournament._id !== action.payload._id) }
        default:
            return state
    }
}

export const TournamentsContextProvider = ({ children }) => {
    const { authUser } = useAuthContext()
    const [state, dispatchTournaments] = useReducer(tournamentsReducer, {
        tournaments: []
    })
    const [tournament, setTournament] = useState(null)

    const [cookies] = useCookies(["tournamentId"])

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (authUser) {
            setIsLoading(true)
            fetchTournaments(authUser.token, authUser._id)
                .then(json => dispatchTournaments({ type: "SET_TOURNAMENTS", payload: json }))
                .catch(setError)
                .finally(() => setIsLoading(false))
        }
    }, [authUser, dispatchTournaments]);

    useEffect(() => {
        if (cookies.tournamentId)
            setTournament(state.tournaments.find(tournament => tournament._id === cookies.tournamentId))
    }, [setTournament, state.tournaments, cookies]);

    return <TournamentsContext.Provider value={{ ...state, dispatchTournaments, tournament, setTournament, error, isLoading }}>
        {children}
    </TournamentsContext.Provider>
}