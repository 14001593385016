import {
    getMatchGuessByMatchIdUrl,
    getMatchGuessByUserIdAndMatchIdUrl,
    getMatchGuessByUserIdUrl, getMatchGuessesUrl
} from "../../helpers/apiUrlProvider";

export async function fetchMatchGuesses(jwt, tournamentId) {
    const response = await fetch(getMatchGuessesUrl(tournamentId), {
        headers: { "Authorization": `Bearer ${jwt}` }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}

export async function fetchMatchGuessesByMatch(jwt, tournamentId, matchId) {
    const response = await fetch(getMatchGuessByMatchIdUrl(tournamentId, matchId), {
        headers: { "Authorization": `Bearer ${jwt}` }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}

export async function fetchMatchGuessesByUser(jwt, tournamentId, userId) {
    const response = await fetch(getMatchGuessByUserIdUrl(tournamentId, userId), {
        headers: { "Authorization": `Bearer ${jwt}` }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}

export async function fetchMatchGuessesByMatchAndUser(jwt, tournamentId, matchId, userId) {
    const response = await fetch(getMatchGuessByUserIdAndMatchIdUrl(tournamentId, userId, matchId), {
        headers: { "Authorization": `Bearer ${jwt}` }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}