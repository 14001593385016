import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuthContext from "../hooks/useAuthContext.js";
import UserDetail from "../components/user/UserDetail.js";
import TippspielSpinner from "../components/util/TippspielSpinner";
import { fetchUserById, fetchUserByUsername } from "../requests/get/fetchUser";
import ConditionalAlert from "../components/util/ConditionalAlert";

export default function User() {
    const { authUser } = useAuthContext()
    const { state } = useLocation()
    const { user, pathname } = state

    const [userToRender, setUserToRender] = useState(user)

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const username = window.location.href.split("/").slice(-1)[0]
        if (authUser && userToRender.username !== username) {
            setIsLoading(true)

            fetchUserByUsername(authUser.token, username)
                .then(setUserToRender)
                .catch(setError)
                .finally(() => setIsLoading(false))
        }
        // eslint-disable-next-line
    }, [authUser, userToRender.username, window.location.href]);

    // todo: get user from url
    // currently a change in username in the url does not trigger rerendering
    useEffect(() => {
        if (authUser && userToRender === null) {
            setIsLoading(true)

            fetchUserById(authUser.token, authUser._id)
                .then(setUserToRender)
                .catch(setError)
                .finally(() => setIsLoading(false))
        }
    }, [authUser, userToRender, pathname]);

    return isLoading ?
        <TippspielSpinner/>
        :
        <>
            <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
            <UserDetail user={userToRender}/>
        </>
}