import { useEffect, useState } from "react";
import useAuthContext from "../../hooks/useAuthContext.js";
import useTournamentsContext from "../../hooks/useTournamentsContext.js";
import getUserTournament from "../../helpers/getUserTournament.js";
import TournamentWinnerGuessList from "../tournamentWinnerGuesses/TournamentWinnerGuessList.js";
import Collapsible from "../util/Collapsible.js";
import TournamentWinnerGuessForm from "../tournamentWinnerGuesses/TournamentWinnerGuessForm.js";
import Container from "react-bootstrap/Container";
import UserResetPasswordForm from "./UserResetPasswordForm";
import Divider from "../util/Divider";
import formatDate from "../../helpers/formatDate";
import ConditionalAlert from "../util/ConditionalAlert";
import FilterableMatchPreviewList from "../match/FilterableMatchPreviewList";
import { MatchGuessesContextProvider } from "../../context/MatchGuessesContext";
import JoinTournamentForm from "../tournament/JoinTournamentForm";

export default function UserDetail({ user }) {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [userTournament, setUserTournament] = useState(null)
    const [latestGuess, setLatestGuess] = useState(null)

    const [message, setMessage] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        setUserTournament(getUserTournament(user, tournament))
    }, [user, tournament]);

    useEffect(() => {
        if (!userTournament)
            return
        const guessCount = userTournament.tournamentWinnerGuesses.length
        setLatestGuess(userTournament.tournamentWinnerGuesses[guessCount - 1])
    }, [userTournament]);

    const useTournamentWinnerGuessFormResults = (tournamentWinnerGuesses) => {
        setUserTournament({
            _id: userTournament._id,
            totalPoints: userTournament.totalPoints,
            tournament: userTournament.tournament,
            tournamentWinnerGuesses: tournamentWinnerGuesses
        })
        // latest guess will be updated due to useEffect dependency
        setMessage("Turniersiegertipp erfolgreich geändert.")
        setError("")
    }

    const resetPasswordFormCallback = () => {
        setMessage("Passwort erfolgreich geändert.")
        setError("")
    }

    const joinTournamentFormCallback = () => {
        setMessage("Dem Turnier erfolgreich beigetreten.")
        setError("")
    }

    return <Container className="user-detail">
        <h3>{user.username}</h3>
        <Divider/>
        <ConditionalAlert variant="success" message={message} onClose={() => setMessage("")}/>
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")}/>
        {user._id === authUser._id && <>
                <Collapsible enableButton="Passwort ändern" disableButton="Abbrechen"
                             hideOnUpdate={resetPasswordFormCallback}>
                    <UserResetPasswordForm onSubmitCallback={resetPasswordFormCallback} onErrorCallback={error => {
                        setError(error);
                        setMessage("")
                    }}/>
                </Collapsible>
                <Collapsible className="mt-1" enableButton="Anderem Turnier beitreten" disableButton="Abbrechen"
                             hideOnUpdate={joinTournamentFormCallback}>
                    <JoinTournamentForm onSubmitCallback={joinTournamentFormCallback} onErrorCallback={error => {
                        setError(error)
                        setMessage("")
                    }} />
                </Collapsible>
                <Divider/>
            </>
        }
        {userTournament ?
            <>
                <p>Gesamtpunkte: {userTournament.totalPoints}</p>
                {latestGuess ?
                    <p>Turniersiegertipp vom {formatDate(latestGuess._created)}: <b>{latestGuess.team.name}</b> ({latestGuess.pointsForGuess} Punkte)</p>
                    :
                    <p>Hat noch keinen Turniersieger getippt.</p>
                }


                {user._id === authUser._id && !tournament.tournamentWinner &&
                    <Collapsible enableButton="Jetzt Turniersieger Tippen" disableButton="Tippen beenden"
                                 hideOnUpdate={useTournamentWinnerGuessFormResults}>
                        <TournamentWinnerGuessForm onSubmitCallback={useTournamentWinnerGuessFormResults}/>
                    </Collapsible>
                }
                {user._id === authUser._id && userTournament.tournamentWinnerGuesses.length > 1 &&
                    <Collapsible className="mt-2" enableButton="Tipphistorie anzeigen" disableButton="Tipphistorie verbergen">
                        <TournamentWinnerGuessList
                            tournamentWinnerGuesses={userTournament.tournamentWinnerGuesses}
                            hideFirstItem={true}
                        />
                    </Collapsible>
                }
                <Divider className="mt-3"/>
                <p className="h5">Tipps von {user.username}:</p>
                <MatchGuessesContextProvider userId={user._id}>
                    <FilterableMatchPreviewList userId={user._id} hideForeignGuesses={user._id !== authUser._id}/>
                </MatchGuessesContextProvider>
            </>
            :
            <p>
                {tournament ?
                    "Dieser Benutzer nimmt nicht an diesem Turnier teil."
                    :
                    "Wähle ein Turnier aus."
                }
            </p>
        }
    </Container>
}