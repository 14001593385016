import { useEffect, useState } from "react";
import useAuthContext from "../../hooks/useAuthContext.js";
import MatchGuessPreview from "./MatchGuessPreview.js";
import Container from "react-bootstrap/Container";
import { fetchMatchGuessesByMatch } from "../../requests/get/fetchMatchGuesses";
import { dateIsInPast } from "../../helpers/compareDates";

export default function MatchGuessPreviewList({ match }) {
    const { authUser } = useAuthContext()

    const [matchGuesses, setMatchGuesses] = useState()

    useEffect(() => {
        if (authUser) {
            fetchMatchGuessesByMatch(authUser.token, match.tournament, match._id)
                .then(json => setMatchGuesses(json.filter(matchGuess => matchGuess.user._id !== authUser._id)))
        }
    }, [authUser, match._id, match.tournament]);

    return <Container className="p-0 ms-0">
        {matchGuesses && matchGuesses.length ?
            matchGuesses.map(matchGuess => (
                <MatchGuessPreview key={matchGuess._id} matchGuess={matchGuess} correctScore={match.currentScore} matchHasStarted={dateIsInPast(match.startTime)} />
            ))
            :
            <p>Bisher wurde kein Tipp abgegeben.</p>
        }
    </Container>
}