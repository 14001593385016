

export function getFormattedGuessScore(hasStarted, correctScore, guessHome, guessAway, winnerIsHomeTeam, guessCorrectness, pointsForGuess) {
    let className = ""
    if (hasStarted)
        className = getGuessCorrectnessColor(guessCorrectness)

    if (guessHome > guessAway || winnerIsHomeTeam === true)
        return <p className={className + " fs-6 lh-1"}><b>{guessHome}</b>:{guessAway} ({getGuessPointString(pointsForGuess)})</p>
    else if (guessAway > guessHome || winnerIsHomeTeam === false)
        return <p className={className + " fs-6 lh-1"}>{guessHome}:<b>{guessAway}</b> ({getGuessPointString(pointsForGuess)})</p>
    else // winnerIsHomeTeam is null or undefined
        return <p className={className + " fs-6 lh-1"}>{guessHome}:{guessAway} ({getGuessPointString(pointsForGuess)})</p>
}

function getGuessCorrectnessColor(guessCorrectness) {
    switch (guessCorrectness) {
        case "correct": return "text-green"
        case "difference": return "text-orange"
        case "tendency": return "text-orange"
        case "wrong": return "text-red"
        default: return ""
    }
}

function getGuessPointString(points) {
    return points === 1 ? "1 Punkt" : points + " Punkte"
}