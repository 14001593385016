import { useCookies } from "react-cookie";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import useAuthContext from "../hooks/useAuthContext";
import useTournamentsContext from "../hooks/useTournamentsContext";

export default function DataUsage() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [cookies, setCookie, resetCookie] = useCookies(["CookieConsent", "authUser", "tournamentId", "filterTeamId", "filterTournamentPhaseId"])

    const disallowCookies = () => {
        setCookie("CookieConsent", "disallow", { path: "/", sameSite: "strict" })
        resetCookie("authUser", { path: "/" })
        resetCookie("tournamentId", { path: "/" })
        localStorage.clear()
    }

    const allowCookies = () => {
        setCookie("CookieConsent", "allow", { path: "/", sameSite: "strict" })
        setCookie("authUser", authUser, { path: "/", sameSite: "strict" })
        let d = new Date()
        d.setDate(d.getDate() + 31)
        setCookie("tournamentId", tournament._id, { expires: d, path: "/", sameSite: "strict" })

        localStorage.setItem("color", authUser.color)
    }

    return <Container>
        <h4>Cookies</h4>
        <pre>
            Cookies werden verwendet, um die Anmeldedaten im Browser zu speichern, bis dieser geschlossen wird.<br/>
            Dadurch muss man sich nicht jedes mal neu anmelden, wenn man den Tab schließt und neu öffnet.<br/>
            Cookies werden verwendet, um das zuletzt ausgewählte Turnier im Browser zu speichern.
        </pre>
        {cookies.CookieConsent && cookies.CookieConsent === "allow" ?
            <Button onClick={disallowCookies}>Cookies nicht mehr erlauben.</Button>
            :
            <Button onClick={allowCookies}>Cookies erlauben.</Button>
        }
    </Container>
}