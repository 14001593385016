import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

export default function Footer() {
    return <footer className="bg-white">
        <Container className="p-0">
            <Nav>
                <Nav.Link as={Link} to="/impressum">Impressum</Nav.Link>
                <Nav.Link as={Link} to="/datenschutzerklärung">Datenverarbeitung</Nav.Link>
            </Nav>
        </Container>
    </footer>
}