import { useEffect, useState } from "react";
import useAuthContext from "../hooks/useAuthContext.js";
import useTournamentsContext from "../hooks/useTournamentsContext.js";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import TippspielSpinner from "../components/util/TippspielSpinner";
import ConditionalAlert from "../components/util/ConditionalAlert";
import useUsers from "../hooks/useUsers";
import formatDate from "../helpers/formatDate";
import Divider from "../components/util/Divider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getUserTournamentWinnerGuessUrl } from "../helpers/apiUrlProvider";
import Collapsible from "../components/util/Collapsible";

export default function EditGuesses() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()
    const { users, dispatchUsers } = useUsers()

    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)


    useEffect(() => {
        const updateWindowWidth = () => setWindowWidth(window.innerWidth)
        window.addEventListener("resize", updateWindowWidth)
        return () => window.removeEventListener("resize", updateWindowWidth)
    }, [window.innerWidth]);

    const removeTournamentWinnerGuess = async (user, userTournamentId, tournamentWinnerGuessId) => {
        // todo: move fetch into own function and then catch finally the response
        const response = await fetch(getUserTournamentWinnerGuessUrl(user._id, tournament._id), {
            method: "DELETE",
            body: JSON.stringify({
                userTournamentId: userTournamentId,
                tournamentWinnerGuessId: tournamentWinnerGuessId
            }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authUser.token}`
            }
        })
        const json = await response.json()

        if (!response.ok)
            setError(json.error)
        else {
            setError("")
            dispatchUsers({ type: "UPDATE", payload: json })
        }
    }

    if (isLoading)
        return <TippspielSpinner/>

    if (!tournament)
        return <Container>Wähle ein Turnier aus.</Container>

    return <>
        <ConditionalAlert variant="success" message={message} onClose={() => setMessage("")} />
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />

        <Container>
            <h3>Turniersiegertipps bearbeiten</h3>
            {users && users.length > 0 && users.map(user => {
                const userTournament = user.tournaments.find(userTournament => tournament._id === userTournament.tournament._id)

                if (!userTournament)
                    return <Container>kein uT</Container>

                return <Collapsible className="mb-1" enableButton={"Turniersiegertipps von " + user.username + " anzeigen"} disableButton={"Turniersiegertipps von " + user.username + " verbergen"}>
                    {userTournament.tournamentWinnerGuesses.length > 0 ?
                        userTournament.tournamentWinnerGuesses.map(tWG =>
                            <Row className="mt-1 align-items-center">
                                <Col className="col-2 col-md-3 justify-content-center">{windowWidth < 768 ? tWG.team.abbreviation : tWG.team.name}</Col>
                                <Col className="col-auto col-md-3">{formatDate(tWG._created)}</Col>
                                <Button className="col-2" onClick={() => removeTournamentWinnerGuess(user, userTournament._id, tWG._id)}>
                                    {windowWidth < 768 ? "X" : "Entfernen"}
                                </Button>
                            </Row>
                        )
                        :
                        <Container>Noch kein Tipp abgegeben.</Container>
                    }
                    <Divider />
                </Collapsible>
                }
            )}
        </Container>
    </>
}