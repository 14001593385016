import useAuthContext from "../../hooks/useAuthContext.js";
import useTournamentsContext from "../../hooks/useTournamentsContext.js";
import { useCookies } from "react-cookie";
import Form from "react-bootstrap/Form";
import fetchTournament from "../../requests/get/fetchTournament";

export default function TournamentDropdown() {
    const { tournament, setTournament, tournaments, isLoading } = useTournamentsContext()
    const { authUser } = useAuthContext()

    const [cookies, setCookie, removeCookie] = useCookies(["tournamentId", "CookieConsent"])

    const handleTournamentDropdownChange = async (e) => {
        if (e.target.value === "") {
            setTournament(null)
            removeCookie("tournamentId")
            return
        }

        fetchTournament(authUser.token, e.target.value)
            .then(json => {
                setTournament(json)
                if (cookies.CookieConsent && cookies.CookieConsent === "allow") {
                    let d = new Date()
                    d.setDate(d.getDate() + 31) // expires in 31 days
                    setCookie("tournamentId", json._id, { expires: d, path: "/", sameSite: "strict" })
                }
            })
            .catch(() => {
                setTournament(null)
                removeCookie("tournamentId")
            })
    }

    return <Form.Select className="tournament-dropdown" name="tournaments" value={tournament ? tournament._id : ""}
                        onChange={handleTournamentDropdownChange}>
        <option value="">{authUser ? "Turnier auswählen" : "Du bist nicht angemeldet"}</option>
        {tournaments.length > 0 && tournaments.map((tournament) =>
            <option key={tournament._id} value={tournament._id}>
                {tournament.name}
            </option>
        )}
    </Form.Select>
}