import Container from "react-bootstrap/Container";
import formatDate from "../../helpers/formatDate";
import { getFormattedGuessScore } from "../../helpers/getFormattedGuessScore";

export default function GuessDetail({ guess, isAuthUser, isLatestGuess, correctScore, matchHasStarted }) {
    return <Container className="p-0 ms-0">
        <p className="fs-6 lh-1">{isAuthUser ? "Dein " : ""}{isLatestGuess ? "Letzter " : ""}Tipp
            vom {formatDate(guess._created)}</p>
        {getFormattedGuessScore(matchHasStarted, correctScore, guess.homeScore, guess.awayScore, guess.winnerIsHomeTeam, guess.guessCorrectness, guess.pointsForGuess)}
    </Container>
}