import { getTournamentByIdRecalculateUrl } from "../../helpers/apiUrlProvider";

export default async function recalculateTournament(jwt, tournamentId) {
    const response = await fetch(getTournamentByIdRecalculateUrl(tournamentId), {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${jwt}`
        }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}
