import { Link } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext.js";
import { useEffect, useState } from "react";
import GuessDetail from "../matchGuess/GuessDetail.js";
import { dateIsInFuture, dateIsInPast } from "../../helpers/compareDates.js";
import Collapsible from "../util/Collapsible.js";
import MatchGuessForm from "../matchGuess/MatchGuessForm.js";
import Container from "react-bootstrap/Container";
import formatDate from "../../helpers/formatDate";
import { getFormattedScores } from "../../helpers/getFormattedScores";
import useMatchGuessesContext from "../../hooks/useMatchGuessesContext";

export default function MatchPreview({ match, matchGuess, userId }) {
    const { authUser } = useAuthContext()
    const { dispatchMatchGuesses } = useMatchGuessesContext()

    const [latestGuess, setLatestGuess] = useState(null)

    useEffect(() => {
        if (authUser && matchGuess && matchGuess.guesses)
            setLatestGuess(matchGuess.guesses.slice(-1)[0])
    }, [authUser, matchGuess]);

    const useFormResults = (updatedMatchGuess) => {
        dispatchMatchGuesses({ type: "UPSERT", payload: updatedMatchGuess })
        setLatestGuess(updatedMatchGuess.guesses.slice(-1)[0])
    }

    return <Container className="row p-0 ms-0 justify-content-evenly">
        <Container className="col-md-5 col-lg-4 col-xl-3">
            <Link className="fs-5" to="/spiel" state={{ match: match }}>
                {match.homeTeam ? match.homeTeam.name : match.homeTeamPlaceholder} - {match.awayTeam ? match.awayTeam.name : match.awayTeamPlaceholder}
            </Link>
            {dateIsInFuture(match.startTime) ?
                <p className="fs-5">am {formatDate(match.startTime)}</p>
                :
                <p className="fs-5">{getFormattedScores(match.currentScore, match.scores)}</p>
            }
        </Container>
        <Container className="col-md-5 col-lg-6 col-xl-7">
            {latestGuess ?
                <>
                    <Container className="p-0 ms-0">
                        <GuessDetail guess={latestGuess} isAuthUser={userId === authUser._id} correctScore={match.currentScore} matchHasStarted={dateIsInPast(match.startTime)}/>
                    </Container>
                    {dateIsInFuture(match.startTime) && userId === authUser._id &&
                        <Collapsible enableButton="Tipp ändern" disableButton="Tippen beenden"
                                     hideOnUpdate={useFormResults}>
                            <MatchGuessForm match={match} onSubmitCallback={useFormResults}/>
                        </Collapsible>
                    }
                </>
                :
                <Container className="p-0 ms-0">
                    {userId === authUser._id ?
                        <>
                            {dateIsInFuture(match.startTime) ?
                                <MatchGuessForm match={match} onSubmitCallback={useFormResults}/>
                                :
                                <p className="fs-6 lh-1">Du hast leider verpasst, einen Tipp abzugeben</p>
                            }
                        </>
                        :
                        <p>Dieser Spieler hat noch keinen Tipp abgegeben.</p>
                    }
                </Container>
            }
        </Container>
    </Container>
}