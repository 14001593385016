import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTournamentsContext from "../../hooks/useTournamentsContext.js";
import getUserTournament from "../../helpers/getUserTournament.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function UserPreview({ user, maxPoints }) {
    const { tournament } = useTournamentsContext()

    const [userTournament, setUserTournament] = useState(null)
    const [latestGuess, setLatestGuess] = useState(null)

    useEffect(() => {
        setUserTournament(getUserTournament(user, tournament))
    }, [user, tournament]);

    useEffect(() => {
        if (!userTournament)
            return
        const guessCount = userTournament.tournamentWinnerGuesses.length
        setLatestGuess(userTournament.tournamentWinnerGuesses[guessCount - 1])
    }, [userTournament]);

    return <Row className="column-gap-0">
        <Container className="col-4 col-sm-3 col-md-2 col-xl-1 text-truncate">
            <Link to={"/spieler/" + user.username} state={{ user: user }}>{user.username}</Link>
        </Container>
        {userTournament ?
            <>
                <Container className="col-5 col-sm-3 col-md-2 col-xl-2">
                    {!tournament.tournamentWinner ?
                        <p>{latestGuess ? latestGuess.team.name : "Keiner"}</p>
                        :
                        <p className={latestGuess && latestGuess.team._id === tournament.tournamentWinner._id ? "text-green" : "text-red"}>
                            {latestGuess ? latestGuess.team.name + " (" + (latestGuess.pointsForGuess ?? 0) + ")" : "Keiner"}
                        </p>
                    }
                </Container>
                <Container className="col-3 col-sm-6 col-md-8 col-xl-9">
                    <ProgressBar variant="info" min={0} max={maxPoints} now={userTournament.totalPoints} label={<b>{userTournament.totalPoints}</b>} />
                </Container>
            </>
            :
            <p>Dieser Spieler nimmt nicht an diesem Turnier teil.</p>
        }
    </Row>
}