import { useEffect, useState } from "react";
import useAuthContext from "./useAuthContext";
import useTournamentsContext from "./useTournamentsContext";
import fetchTournamentPhases from "../requests/get/fetchTournamentPhases";

export default function useTournamentPhases() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [tournamentPhases, setTournamentPhases] = useState([])

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (authUser && tournament) {
            setIsLoading(true)

            fetchTournamentPhases(authUser.token, tournament._id)
                .then(setTournamentPhases)
                .catch(setError)
                .finally(() => setIsLoading(false))
        }
    }, [authUser, tournament]);

    return { tournamentPhases, setTournamentPhases, error, isLoading }
}