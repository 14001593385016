import Container from "react-bootstrap/Container";

export default function Imprint() {
    return <Container>
        <pre>
            Diese Webseite ist im Rahmen eines Praxisprojekts im Bachelor-Studiengang Angewandte Informatik an der Fachhochschule Erfurt entstanden.

            Harald Wagner:
            Kronenburggasse 20
            99084 Erfurt
            Deutschland
            harald.wagner@fh-erfurt.de

            FH Erfurt:
            Altonaer Straße 25
            99085 Erfurt
            Deutschland
        </pre>
    </Container>
}