import { useEffect, useState } from "react";
import useAuthContext from "../../hooks/useAuthContext.js";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import ConditionalAlert from "../util/ConditionalAlert";
import addGuess from "../../requests/post/addGuess";
import Col from "react-bootstrap/Col";
import Checkbox from "../util/Checkbox";

export default function MatchGuessForm({ match, onSubmitCallback }) {
    const { authUser } = useAuthContext()

    const [homeScore, setHomeScore] = useState(null)
    const [awayScore, setAwayScore] = useState(null)
    const [winnerIsHomeTeam, setWinnerIsHomeTeam] = useState(null)

    const [error, setError] = useState("")

    useEffect(() => {
        if (homeScore === awayScore)
            setWinnerIsHomeTeam(null)
        else
            setWinnerIsHomeTeam(homeScore > awayScore)
    }, [homeScore, awayScore]);

    const submitFormData = async (e) => {
        e.preventDefault()
        const guess = {
            user: authUser._id,
            match: match._id,
            guess: {
                homeScore: homeScore ?? "0",
                awayScore: awayScore ?? "0",
                winnerIsHomeTeam: winnerIsHomeTeam
            }
        }

        addGuess(authUser.token, match.tournament, guess)
            .then(onSubmitCallback)
            .catch(setError)
    }

    const submitRandomData = async (e) => {
        e.preventDefault()

        const randomHomeScore = Math.floor(Math.random() * 6).toString()
        const randomAwayScore = Math.floor(Math.random() * 6).toString()
        const randomWinnerIsHomeTeam = randomHomeScore === randomAwayScore ? Math.random() < 0.5 : randomHomeScore > randomAwayScore

        const guess = {
            user: authUser._id,
            match: match._id,
            guess: {
                homeScore: randomHomeScore,
                awayScore: randomAwayScore,
                winnerIsHomeTeam: randomWinnerIsHomeTeam
            }
        }

        addGuess(authUser.token, match.tournament, guess)
            .then(onSubmitCallback)
            .catch(setError)
    }

    if (!match.homeTeam || !match.awayTeam)
        return <p>Du kannst Tippen, sobald beide Teams feststehen.</p>

    return <Container className="p-0 ms-0">
        <Form onSubmit={submitFormData}>
            <Row className="text-center justify-content-evenly">
                <Form.Group className="col-5" controlId={"match-guess-" + match._id + "-home-score"}>
                    <Form.Label>{match.homeTeam.name}:</Form.Label>
                    <Form.Control
                        type="number"
                        min="0"
                        max="99999"
                        onChange={(e) => setHomeScore(e.target.value)}
                        value={homeScore ?? ""}
                        required
                    />
                </Form.Group>
                <Form.Group className="col-5" controlId={"match-guess-" + match._id + "-away-score"}>
                    <Form.Label>{match.awayTeam.name}:</Form.Label>
                    <Form.Control
                        className="m-0"
                        type="number"
                        min="0"
                        max="99999"
                        onChange={(e) => setAwayScore(e.target.value)}
                        value={awayScore ?? ""}
                        required
                    />
                </Form.Group>
            </Row>
            <Row className="pt-1 justify-content-center text-center" hidden={homeScore !== awayScore || homeScore === null || awayScore === null}>
                <Checkbox className="col-6 col-sm-4 order-0"
                          disabled={homeScore !== awayScore} checked={winnerIsHomeTeam === true}
                          onClick={() => setWinnerIsHomeTeam(winnerIsHomeTeam === true ? null : true)} />
                <Col className="col-12 col-sm-4 order-2 order-sm-1">
                    {winnerIsHomeTeam === null ? "Unentschieden" : (winnerIsHomeTeam ? match.homeTeam.name : match.awayTeam.name) + " gewinnt"}
                </Col>
                <Checkbox className="col-6 col-sm-4 order-1 order-sm-2"
                          disabled={homeScore !== awayScore} checked={winnerIsHomeTeam === false}
                          onClick={() => setWinnerIsHomeTeam(winnerIsHomeTeam === false ? null : false)} />
            </Row>
            <Row className="justify-content-evenly pt-1">
                <Button className="col-5" variant="primary" type="submit" name="use-form-data">
                    Tipp absenden!
                </Button>
                <Button className="col-5" variant="primary" type="button" name="use-random-data"
                        onClick={submitRandomData}>
                    Ergebnis würfeln!
                </Button>
            </Row>
        </Form>
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
    </Container>
}