import { getTournamentPhaseByIdUrl } from "../../helpers/apiUrlProvider";

export default async function updateTournamentPhase(jwt, tournamentId, tournamentPhaseId, data) {
    const response = await fetch(getTournamentPhaseByIdUrl(tournamentId, tournamentPhaseId), {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}