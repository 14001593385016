import { useEffect, useState } from "react";
import useAuthContext from "./useAuthContext";
import useTournamentsContext from "./useTournamentsContext";
import fetchTeams from "../requests/get/fetchTeams";

export default function useTeams() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [teams, setTeams] = useState([])

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (authUser && tournament) {
            setIsLoading(true)

            fetchTeams(authUser.token, tournament._id)
                .then(setTeams)
                .catch(setError)
                .finally(() => setIsLoading(false))
        }
    }, [authUser, tournament]);

    return { teams, setTeams, error, isLoading }
}