import { useEffect, useState } from "react";
import fetchMatches from "../requests/get/fetchMatches";
import useAuthContext from "./useAuthContext";
import useTournamentsContext from "./useTournamentsContext";

export default function useMatches() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [matches, setMatches] = useState([])

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (authUser && tournament) {
            setIsLoading(true)

            fetchMatches(authUser.token, tournament._id)
                .then(setMatches)
                .catch(setError)
                .finally(() => setIsLoading(false))
        }
    }, [authUser, tournament]);

    return { matches, setMatches, error, isLoading }
}