import { useEffect, useReducer, useState } from "react";
import useAuthContext from "./useAuthContext";
import useTournamentsContext from "./useTournamentsContext";
import fetchUsers from "../requests/get/fetchUsers";

function usersReducer(state, action) {
    switch (action.type) {
        case "SET": return { users: action.payload }
        case "UPDATE": return { users: state.users.map(user => user._id === action.payload._id ? action.payload : user) }
        case "UPDATE_COLOR": return { users: state.users.map(user => user._id === action.payload._id ? { ...user, color: action.payload.color } : user) }
        default:return state
    }
}

export default function useUsers() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [state, dispatchUsers] = useReducer(usersReducer,{
        users: []
    })

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (authUser && tournament) {
            setIsLoading(true)

            fetchUsers(authUser.token, tournament._id)
                .then(json => dispatchUsers({ type: "SET", payload: json }))
                .catch(setError)
                .finally(() => setIsLoading(false))
        }
    }, [authUser, tournament]);

    return { ...state, dispatchUsers, error, isLoading }
}