import { Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout.js";
import useAuthContext from "../../hooks/useAuthContext.js";
import TournamentDropdown from "./TournamentDropdown.js";
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Container from "react-bootstrap/Container";

export default function TippspielNavbar() {
    const { logout } = useLogout()
    const { authUser } = useAuthContext()

    return <header className="bg-white text-center">
        <Navbar expand="lg" collapseOnSelect>
            <Container>
                <Navbar.Brand>
                    {authUser
                        ? <TournamentDropdown eventKey={0}/>
                        : <Nav.Link as={Link} to="/anmelden" eventKey={0}>Melde dich zunächst an.</Nav.Link>
                    }
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav flex-row">
                    <Nav className="m-2 mt-lg-0 flex-grow-1 grid gap-3 h4">
                        <Nav.Item className="col">
                            <Nav.Link as={Link} to="/" eventKey={1}>Punktestand</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="col">
                            <Nav.Link as={Link} to="/turnierverlauf" eventKey={2}>Turnierverlauf</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="col">
                            <Nav.Link as={Link} to="/statistiken" eventKey={3}>Statistiken</Nav.Link>
                        </Nav.Item>
                        {authUser && authUser.isAdmin &&
                            <Nav.Item className="col">
                                <NavDropdown title="Admin-Seiten" id="basic-nav-dropdowm">
                                    <NavDropdown.Item as={Link} to="/tippspiel-erstellen" eventKey={4.1}>
                                        Neues Turnier anlegen <span>+</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/tippspiel-bearbeiten" eventKey={3.2}>
                                        Turnier bearbeiten
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/tipps-bearbeiten" eventKey={3.3}>
                                        Tipps CRUD
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        }
                    </Nav>
                    <Nav className="my-2 my-lg-0 flex-column w-25">
                        {authUser ?
                            <>
                                <Nav.Item className="">
                                    <Nav.Link as={Link} to={"/spieler/" + authUser.username} state={{ user: null }}
                                              eventKey={5.2}>
                                        Angemeldet als: {authUser.username}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border border-2 border-primary rounded-1">
                                    <Nav.Link onClick={() => logout()} eventKey={5.1}>
                                        Abmelden
                                    </Nav.Link>
                                </Nav.Item>
                            </>
                            :
                            <>
                                <Nav.Item className="border border-2 border-primary rounded-1">
                                    <Nav.Link as={Link} to="/anmelden" eventKey={5.1}>Anmelden</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border border-2 border-primary rounded-1 mt-1">
                                    <Nav.Link className="border-primary" as={Link} to="/registrieren"
                                              eventKey={5.2}>Registrieren</Nav.Link>
                                </Nav.Item>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
}