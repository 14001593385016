export function dateIsInPast(date) {
    if (typeof date === "string")
        date = Date.parse(date)

    return date < Date.now()
}

export function dateIsInFuture(date) {
    if (typeof date === "string")
        date = Date.parse(date)

    return date > Date.now()
}