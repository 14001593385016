import { useState } from "react";
import useAuthContext from "../../hooks/useAuthContext.js";
import useTournamentsContext from "../../hooks/useTournamentsContext.js";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ConditionalAlert from "../util/ConditionalAlert";
import createTournamentPhase from "../../requests/post/createTournamentPhase";
import changeMatchTournamentPhase from "../../requests/put/changeMatchTournamentPhase";
import deleteTournamentPhase from "../../requests/delete/deleteTournamentPhase";

export default function TournamentPhaseJoinForm({ tournamentPhases, onSubmitCallback }) {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [name, setName] = useState("")
    const [matchGuessPointsCorrect, setMatchGuessPointsCorrect] = useState("1")
    const [matchGuessPointsDifference, setMatchGuessPointsDifference] = useState("1")
    const [matchGuessPointsTendency, setMatchGuessPointsTendency] = useState("1")
    const [matchGuessPointsWrong, setMatchGuessPointsWrong] = useState("0")
    const [winnerGuessMultiplier, setWinnerGuessMultiplier] = useState("1")
    const [selectedTournamentPhaseIds, setSelectedTournamentPhaseIds] = useState([])

    const [error, setError] = useState("")

    const deleteTournamentPhases = async (newTournamentPhase) => {
        for (const selectedTournamentPhaseId of selectedTournamentPhaseIds) {
            deleteTournamentPhase(authUser.token, tournament._id, selectedTournamentPhaseId)
                .then(() => {
                })
                .catch(setError)
        }

        onSubmitCallback({ newTournamentPhase, oldTournamentPhaseIds: selectedTournamentPhaseIds })
        setName("")
        setMatchGuessPointsCorrect("1")
        setMatchGuessPointsDifference("1")
        setMatchGuessPointsTendency("1")
        setMatchGuessPointsWrong("0")
        setWinnerGuessMultiplier("1")
        setError("")
        setSelectedTournamentPhaseIds([])
    }

    const changeTournamentPhases = async (newTournamentPhase) => {
        // update all matches with old tournament ids
        const data = {
            oldTournamentPhaseIds: selectedTournamentPhaseIds,
            newTournamentPhaseId: newTournamentPhase._id
        }
        changeMatchTournamentPhase(authUser.token, tournament._id, data)
            .then(() => {
                deleteTournamentPhases(newTournamentPhase)
            })
            .catch(setError)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (selectedTournamentPhaseIds.length < 2) {
            setError("Wähle mindestens zwei Turnierphase aus.")
            return
        }

        // create new tournament Phase
        const selectedTournamentPhases = tournamentPhases
            .filter(tournamentPhase => selectedTournamentPhaseIds.includes(tournamentPhase._id))
        const newTournamentPhase = {
            name,
            tournament,
            matchGuessPoints: {
                correct: matchGuessPointsCorrect,
                difference: matchGuessPointsDifference,
                tendency: matchGuessPointsTendency,
                wrong: matchGuessPointsWrong
            },
            winnerGuessMultiplier,
            startDate: new Date(
                selectedTournamentPhases
                    .map(tournamentPhase => Date.parse(tournamentPhase.startDate))
                    .sort((a, b) => a - b) // smallest date first
                    [0]
            ).toISOString(),
            endDate: new Date(
                selectedTournamentPhases
                    .map(tournamentPhase => Date.parse(tournamentPhase.endDate))
                    .sort((a, b) => b - a) // largest date first
                    [0]
            ).toISOString(),
            order: selectedTournamentPhases
                .map(tournamentPhase => tournamentPhase.order)
                .sort((a, b) => a - b)
                [0]
        }
        createTournamentPhase(authUser.token, tournament._id, newTournamentPhase)
            .then((json) => changeTournamentPhases(json))
            .catch(setError)
    }

    return <Form onSubmit={handleSubmit}>
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
        <Form.Group controlId="join-tournament-phases-new-name">
            <Form.Label>Neuer Name:</Form.Label>
            <Form.Control
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
            />
        </Form.Group>
        <Container>
            <Form.Group controlId="join-tournament-phases-match-guess-points-correct">
                <Form.Label>Punktefaktor für Spieletipps (Vollständig Korrekt):</Form.Label>
                <Form.Control
                    type="number"
                    required
                    min="0"
                    max="99999"
                    onChange={(e) => setMatchGuessPointsCorrect(e.target.value)}
                    value={matchGuessPointsCorrect}
                />
            </Form.Group>
            <Form.Group controlId="join-tournament-phases-match-guess-points-difference">
                <Form.Label>Punktefaktor für Spieletipps (Differenz Korrekt):</Form.Label>
                <Form.Control
                    type="number"
                    required
                    min="0"
                    max="99999"
                    onChange={(e) => setMatchGuessPointsDifference(e.target.value)}
                    value={matchGuessPointsDifference}
                />
            </Form.Group>
            <Form.Group controlId="join-tournament-phases-match-guess-points-tendency">
                <Form.Label>Punktefaktor für Spieletipps (Sieger Korrekt):</Form.Label>
                <Form.Control
                    type="number"
                    required
                    min="0"
                    max="99999"
                    onChange={(e) => setMatchGuessPointsTendency(e.target.value)}
                    value={matchGuessPointsTendency}
                />
            </Form.Group>
            <Form.Group controlId="join-tournament-phases-match-guess-points-wrong">
                <Form.Label>Punktefaktor für Spieletipps (Falsch):</Form.Label>
                <Form.Control
                    type="number"
                    required
                    min="0"
                    max="99999"
                    onChange={(e) => setMatchGuessPointsWrong(e.target.value)}
                    value={matchGuessPointsWrong}
                />
            </Form.Group>
        </Container>
        <Form.Group controlId="join-tournament-phases-winner-guess-muliplier">
            <Form.Label>Punktefaktor für Turniersiegertipps:</Form.Label>
            <Form.Control
                type="number"
                required
                min="0"
                max="99999"
                onChange={(e) => setWinnerGuessMultiplier(e.target.value)}
                value={winnerGuessMultiplier}
            />
        </Form.Group>
        <h5>Wähle die Turnierphasen aus, die du zusammenfassen möchtest:</h5>
        <Container>
            {tournamentPhases && tournamentPhases.length > 0 ?
                tournamentPhases.map(tournamentPhase =>
                    <Form.Group key={tournamentPhase._id}>
                        <Form.Check
                            type="checkbox"
                            label={tournamentPhase.name}
                            value={tournamentPhase._id}
                            onChange={(e) => {
                                if (e.target.checked)
                                    setSelectedTournamentPhaseIds([e.target.value, ...selectedTournamentPhaseIds])
                                else
                                    setSelectedTournamentPhaseIds(selectedTournamentPhaseIds.splice(selectedTournamentPhaseIds.indexOf(e.target.value), 1))
                            }}
                        />
                    </Form.Group>
                )
                :
                <p>Dieses Turnier hat keine Turnierphasen</p>
            }
        </Container>
        <p>Achtung, diese Änderung kann nicht rückgängig gemacht werden!</p>
        <Button variant="primary" type="submit">Änderungen speichern!</Button>
    </Form>
}