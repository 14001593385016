
export function getFormattedScores(currentScore, allScores) {
    const sortedScores = allScores.sort((a, b) => b.resultType - a.resultType)

    switch (sortedScores.length) {
        case 1: return <>{getFormattedScore(sortedScores[0])}</>
        case 2: return <>{getFormattedScore(sortedScores[0])} ({getFormattedScore(sortedScores[1])})</>
        case 3: return <>{getFormattedScore(sortedScores[0])} n.V. ({getFormattedScore(sortedScores[1])}, {getFormattedScore(sortedScores[2])})</>
        case 4: return <>{getFormattedScore(sortedScores[0])} n.E. ({getFormattedScore(sortedScores[1])} n.V., {getFormattedScore(sortedScores[2])}, {getFormattedScore(sortedScores[3])})</>
        default: return <>{getFormattedScore(currentScore)}</>
    }
}

function getFormattedScore({ home, away }) {
    if (home > away)
        return <><b>{home}</b>:{away}</>
    else if (away > home)
        return <>{home}:<b>{away}</b></>
    else
        return <>{home}:{away}</>

}