import { useState } from "react";
import { getUserTournamentWinnerGuessUrl } from "../../helpers/apiUrlProvider.js";
import useAuthContext from "../../hooks/useAuthContext.js";
import useTournamentsContext from "../../hooks/useTournamentsContext.js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ConditionalAlert from "../util/ConditionalAlert";
import TeamDropdown from "../tournament/TeamDropdown";

export default function TournamentWinnerGuessForm({ onSubmitCallback }) {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [teamId, setTeamId] = useState("")

    const [error, setError] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!teamId) {
            setError("Wähle ein Team aus!")
            return
        }

        // todo: move fetch into own function and then catch finally the response
        const response = await fetch(getUserTournamentWinnerGuessUrl(authUser._id, tournament._id), {
            method: "POST",
            body: JSON.stringify({
                teamId: teamId
            }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authUser.token}`
            }
        })
        const json = await response.json()

        if (!response.ok)
            setError(json.error)
        else {
            setError("")
            onSubmitCallback(json.tournamentWinnerGuesses)
        }
    }

    if (tournament.tournamentWinner)
        return <p>Der Turniersieger steht fest, du kannst nicht mehr tippen.</p>

    return <Form className="tournamentwinnerguess-form" onSubmit={handleSubmit}>
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
        <TeamDropdown className="ms-0 p-0 mt-1"  emptySelectionString="Turniersieger auswählen" selected={teamId} allowRandomOption={true} onSelectCallback={setTeamId} />
        <Button className="mt-1" variant="primary" type="submit">Tipp absenden!</Button>
    </Form>
}