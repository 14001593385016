import { useState } from "react";
import useRegister from "../hooks/useRegister.js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ConditionalAlert from "../components/util/ConditionalAlert";


export default function Register() {
    const { register, error, isLoading, setError } = useRegister()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [registerCode, setRegisterCode] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault()

        await register(username, password, confirmPassword, registerCode)
    }

    return <Form onSubmit={handleSubmit}>
        <h3>Registrieren</h3>
        <Form.Group controlId="register-username">
            <Form.Label>Benutzername:</Form.Label>
            <Form.Control
                type="text"
                required
                onChange={(e) => setUsername(e.target.value)}
                value={username}
            />
        </Form.Group>
        <Form.Group controlId="register-password">
            <Form.Label>Passwort:</Form.Label>
            <Form.Control
                type="password"
                required
                minLength="8"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
        </Form.Group>
        <Form.Group controlId="register-password-confirm">
            <Form.Label>Passwort bestätigen:</Form.Label>
            <Form.Control
                type="password"
                required
                minLength="8"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
            />
        </Form.Group>
        <Form.Group controlId="register-registercode">
            <Form.Label>Registrierungscode:</Form.Label>
            <Form.Control
                type="text"
                required
                minLength="9"
                maxLength="9"
                onChange={(e) => setRegisterCode(e.target.value)}
                value={registerCode}
            />
        </Form.Group>

        <Button className="mt-1" variant="primary" type="submit" disabled={isLoading}>Registrieren</Button>
        <ConditionalAlert className="mt-1" variant="danger" message={error} onClose={() => setError("")}/>
    </Form>
}