import { createContext, useEffect, useReducer, useState } from "react";
import { fetchMatchGuesses, fetchMatchGuessesByUser } from "../requests/get/fetchMatchGuesses";
import useAuthContext from "../hooks/useAuthContext";
import useTournamentsContext from "../hooks/useTournamentsContext";

export const MatchGuessesContext = createContext()

export function matchGuessReducer(state, action) {
    switch (action.type) {
        case "SET": return { matchGuesses: action.payload }
        case "UPSERT": {
            let didExist = false
            const newState = state.matchGuesses.map(mG => {
                if (mG._id === action.payload._id) {
                    didExist = true
                    return action.payload
                }
                return mG
            })
            if (!didExist)
                newState.push(action.payload)
            return { matchGuesses: newState }
        }
        default:
            return state
    }
}

export const MatchGuessesContextProvider = ({ children, userId }) => {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [matchGuesses, dispatchMatchGuesses] = useReducer(matchGuessReducer, {
        matchGuesses: []
    })

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (authUser && tournament) {
            setIsLoading(true)
            if (!userId) {
                fetchMatchGuesses(authUser.token, tournament._id)
                    .then(json => dispatchMatchGuesses({ type: "SET", payload: json }))
                    .catch(setError)
                    .finally(() => setIsLoading(false))
            }
            else {
                fetchMatchGuessesByUser(authUser.token, tournament._id, userId)
                    .then(json => dispatchMatchGuesses({ type: "SET", payload: json }))
                    .catch(setError)
                    .finally(() => setIsLoading(false))
            }
        }
    }, [authUser, tournament, userId, dispatchMatchGuesses]);

    return <MatchGuessesContext.Provider value={{ ...matchGuesses, dispatchMatchGuesses, error, isLoading }}>
        {children}
    </MatchGuessesContext.Provider>
}