import { useEffect, useState } from "react";
import useAuthContext from "../hooks/useAuthContext.js";
import useTournamentsContext from "../hooks/useTournamentsContext.js";
import UserPreviewList from "../components/user/UserPreviewList.js";
import Collapsible from "../components/util/Collapsible.js";
import Container from "react-bootstrap/Container";
import Divider from "../components/util/Divider";
import ConditionalAlert from "../components/util/ConditionalAlert";
import TippspielSpinner from "../components/util/TippspielSpinner";
import useUsers from "../hooks/useUsers";
import joinErrors from "../components/util/joinErrors";

export default function Home() {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const { users, error: userError, isLoading: usersIsLoading } = useUsers()

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setError(joinErrors(userError))
    }, [userError]);

    useEffect(() => {
        setIsLoading(usersIsLoading)
    }, [usersIsLoading]);

    if (isLoading)
        return <TippspielSpinner/>

    return <Container>
        <ConditionalAlert variant="danger" error={error} onClose={() => setError("")} />
        {tournament ?
            <>
                {authUser.isAdmin && <>
                        <Collapsible enableButton="Turniercode anzeigen" disableButton="Turniercode verbergen"
                                     hideOnUpdate={tournament}>
                            <Container className="p-0 ms-0">Registrierungscode
                                für {tournament.name}: {tournament.authInfo.registerCode}</Container>
                        </Collapsible>
                    <Divider />
                    </>
                }
                {tournament.tournamentWinner && <>
                        <Container className="ms-0 p-0">
                            Turniersieger: {tournament.tournamentWinner.name}
                        </Container>
                        <Divider />
                    </>
                }
                {users.length > 0 ?
                    <UserPreviewList users={users}/>
                    :
                    <p>Dieses Turnier hat keine Teilnehmer.</p>
                }
            </>
            :
            <p>Wähle ein Turnier aus.</p>
        }
    </Container>
}