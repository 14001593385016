import GoalDetail from "./GoalDetail.js";
import MatchGuessDetail from "../matchGuess/MatchGuessDetail.js";
import MatchGuessPreviewList from "../matchGuess/MatchGuessPreviewList.js";
import { dateIsInPast } from "../../helpers/compareDates.js";
import Container from "react-bootstrap/Container";
import Divider from "../util/Divider";
import Collapsible from "../util/Collapsible";
import formatDate from "../../helpers/formatDate";
import { getFormattedScores } from "../../helpers/getFormattedScores";

export default function MatchDetail({ match }) {
    return <Container className="match-detail">
        <h3>{match.tournamentPhase.name}</h3>
        <Divider/>
        <p className="fs-4 lh-1">{match.homeTeam ? match.homeTeam.name : match.homeTeamPlaceholder} - {match.awayTeam ? match.awayTeam.name : match.awayTeamPlaceholder}</p>
        {match.location &&
            <p>{match.location}</p>
        }
        <p>Spielbeginn: {formatDate(match.startTime)}</p>
        {dateIsInPast(match.startTime) &&
            <p className="fs-4 lh-1 mb-3">{getFormattedScores(match.currentScore, match.scores)}</p>
        }

        {match.goals && match.goals.length > 0 &&
            <Collapsible enableButton="Tore anzeigen" disableButton="Tore verstecken">
                <Divider/>
                {match.goals.length > 0 ?
                    match.goals
                        .sort((a, b) => a.newScore.home + a.newScore.home - b.newScore.home - b.newScore.home)
                        .map(goal => <GoalDetail key={goal.minute} goal={goal}/>)
                    :
                    <p>Es sind keine Tore gefallen.</p>
                }
            </Collapsible>
        }
        <Divider/>
        <MatchGuessDetail match={match}/>
        <Divider/>
        {dateIsInPast(match.startTime) ?
            <MatchGuessPreviewList match={match}/>
            :
            <Container className="p-0 ms-0">Sobald das Spiel begonnen hat, kannst du die Tipps von anderen Teilnehmern sehen.</Container>
        }
    </Container>
}