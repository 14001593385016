import Container from "react-bootstrap/Container";
import formatDate from "../../helpers/formatDate";
import { getFormattedGuessScore } from "../../helpers/getFormattedGuessScore";

export default function MatchGuessPreview({ matchGuess, correctScore, matchHasStarted }) {
    const latestGuess = matchGuess.guesses.at(-1)

    return <Container className="p-0 ms-0">
        <p className="fs-5 lh-1">Tipp von {matchGuess.user.username} (vom {formatDate(latestGuess._created)}):</p>
        {getFormattedGuessScore(matchHasStarted, correctScore, latestGuess.homeScore, latestGuess.awayScore, latestGuess.winnerIsHomeTeam, latestGuess.guessCorrectness, latestGuess.pointsForGuess)}
    </Container>
}