import { getUserColorByIdUrl } from "../../helpers/apiUrlProvider";

export default async function updateUserColor(jwt, userId, color) {
    const response = await fetch(getUserColorByIdUrl(userId), {
        method: "PUT",
        body: JSON.stringify({
            color: color
        }),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        }
    })
    const json = await response.json()

    return new Promise((resolve, reject) => response.ok ? resolve(json) : reject(json.error))
}
