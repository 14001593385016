import { useEffect, useState } from "react";
import useAuthContext from "../../hooks/useAuthContext.js";
import useTournamentsContext from "../../hooks/useTournamentsContext.js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import updateTournamentPhase from "../../requests/put/updateTournamentPhase";
import ConditionalAlert from "../util/ConditionalAlert";
import Container from "react-bootstrap/Container";
import Checkbox from "../util/Checkbox";
import Row from "react-bootstrap/Row";

export default function TournamentPhaseForm({ tournamentPhase, onSubmitCallback }) {
    const { authUser } = useAuthContext()
    const { tournament } = useTournamentsContext()

    const [name, setName] = useState("")
    const [matchGuessPointsCorrect, setMatchGuessPointsCorrect] = useState("")
    const [matchGuessPointsDifference, setMatchGuessPointsDifference] = useState("")
    const [matchGuessPointsTendency, setMatchGuessPointsTendency] = useState("")
    const [matchGuessPointsWrong, setMatchGuessPointsWrong] = useState("")
    const [winnerGuessMultiplier, setWinnerGuessMultiplier] = useState("")

    const [error, setError] = useState("")

    useEffect(() => {
        if (!tournamentPhase)
            return

        setName(tournamentPhase.name)
        setMatchGuessPointsCorrect(tournamentPhase.matchGuessPoints.correct ?? "")
        setMatchGuessPointsDifference(tournamentPhase.matchGuessPoints.difference ?? "")
        setMatchGuessPointsTendency(tournamentPhase.matchGuessPoints.tendency ?? "")
        setMatchGuessPointsWrong(tournamentPhase.matchGuessPoints.wrong ?? "")

        setWinnerGuessMultiplier(tournamentPhase.winnerGuessMultiplier ?? "1")
    }, [tournamentPhase]);

    const handleSubmit = async (e) => {
        e.preventDefault()

        const updateData = {
            name,
            matchGuessPoints: {
                correct: matchGuessPointsCorrect ?? null,
                difference: matchGuessPointsDifference ?? null,
                tendency: matchGuessPointsTendency ?? null,
                wrong: matchGuessPointsWrong ?? null
            },
            winnerGuessMultiplier
        }
        updateTournamentPhase(authUser.token, tournament._id, tournamentPhase._id, updateData)
            .then(onSubmitCallback)
            .catch(setError)
    }

    return <Form onSubmit={handleSubmit}>
        <ConditionalAlert variant="danger" message={error} onClose={() => setError("")} />
        <Form.Group controlId={"edit-tournament-phase-" + tournamentPhase.name + "-name"}>
            <Form.Label>Name:</Form.Label>
            <Form.Control
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
            />
        </Form.Group>
        <Container>
            <Form.Group controlId={"edit-tournament-phase-" + tournamentPhase.name + "-match-guess-points-correct"}>
                <Form.Label>Punktefaktor für Spieletipps (Vollständig Korrekt):</Form.Label>
                <Row className="p-0 ms-0 align-items-center gap-1">
                    <Container className="p-0 ms-0 col-11">
                        <Form.Control
                            type="number"
                            min="0"
                            max="99999"
                            onChange={(e) => setMatchGuessPointsCorrect(e.target.value)}
                            value={matchGuessPointsCorrect}
                        />
                    </Container>
                    <Checkbox className="col-1" checked={matchGuessPointsCorrect !== ""}
                              onClick={() => setMatchGuessPointsCorrect(matchGuessPointsCorrect ? "" : "1")} />
                </Row>
            </Form.Group>
            <Form.Group controlId={"edit-tournament-phase-" + tournamentPhase.name + "-match-guess-points-difference"}>
                <Form.Label>Punktefaktor für Spieletipps (Differenz Korrekt):</Form.Label>
                <Row className="p-0 ms-0 align-items-center gap-1">
                    <Container className="p-0 ms-0 col-11">
                        <Form.Control
                            type="number"
                            min="0"
                            max="99999"
                            onChange={(e) => setMatchGuessPointsDifference(e.target.value)}
                            value={matchGuessPointsDifference}
                        />
                    </Container>
                    <Checkbox checked={matchGuessPointsDifference !== ""}
                              onClick={() => setMatchGuessPointsDifference(matchGuessPointsDifference ? "" : "1")} />
                </Row>
            </Form.Group>
            <Form.Group controlId={"edit-tournament-phase-" + tournamentPhase.name + "-match-guess-points-tendency"}>
                <Form.Label>Punktefaktor für Spieletipps (Sieger Korrekt):</Form.Label>
                <Row className="p-0 ms-0 align-items-center gap-1">
                    <Container className="p-0 ms-0 col-11">
                        <Form.Control
                            type="number"
                            min="0"
                            max="99999"
                            onChange={(e) => setMatchGuessPointsTendency(e.target.value)}
                            value={matchGuessPointsTendency}
                        />
                    </Container>
                    <Checkbox checked={matchGuessPointsTendency !== ""}
                              onClick={() => setMatchGuessPointsTendency(matchGuessPointsTendency ? "" : "1")} />
                </Row>
            </Form.Group>
            <Form.Group controlId={"edit-tournament-phase-" + tournamentPhase.name + "-match-guess-points-wrong"}>
                <Form.Label>Punktefaktor für Spieletipps (Falsch):</Form.Label>
                <Row className="p-0 ms-0 align-items-center gap-1">
                    <Container className="p-0 ms-0 col-11">
                        <Form.Control
                            type="number"
                            min="0"
                            max="99999"
                            onChange={(e) => setMatchGuessPointsWrong(e.target.value)}
                            value={matchGuessPointsWrong}
                        />
                    </Container>
                    <Checkbox checked={matchGuessPointsWrong !== ""}
                              onClick={() => setMatchGuessPointsWrong(matchGuessPointsWrong ? "" : "0")} />
                </Row>
            </Form.Group>
        </Container>
        <Form.Group controlId={"edit-tournament-phase-" + tournamentPhase.name + "-winner-guess-multiplier"}>
            <Form.Label>Punktefaktor für Turniersiegertipps:</Form.Label>
            <Form.Control
                type="number"
                required
                min="0"
                max="99999"
                onChange={(e) => setWinnerGuessMultiplier(e.target.value)}
                value={winnerGuessMultiplier}
            />
        </Form.Group>

        <Button variant="primary" type="submit">Änderungen speichern!</Button>
    </Form>
}