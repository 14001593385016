import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ProgressBarForUser({ user, min, max, now, label, className }) {
    return <Row className={"column-gap-0 " + className ?? ""}>
        <Col className="col-4 col-md-3 col-lg-2 text-truncate">
            <Link to={"/spieler/" + user.username} state={{ user: user }}>{user.username}</Link>
        </Col>
        <Col className="col-8 col-md-9 col-xl-10">
            <ProgressBar variant="info" min={min ?? 0} max={max ?? 1} now={now} label={<b>{label ?? now}</b>} />
        </Col>
        <Row className="me-0 no-gutters">
            <p className="col-0 col-lg-2"></p>
            <p className="col-3 col-lg-2 text-green">Richtig: {user.guessCorrectnessCounts.correct}</p>
            <p className="col-3 col-lg-2 text-orange">Sieger richtig: {user.guessCorrectnessCounts.tendency}</p>
            <p className="col-3 col-lg-2 text-red">Falsch: {user.guessCorrectnessCounts.wrong}</p>
            <p className="col-3 col-lg-2">Nicht getippt: {user.guessCorrectnessCounts.none}</p>
        </Row>
    </Row>
}