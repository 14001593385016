
export default function getLatestGuess(matchGuess) {
    return matchGuess ? matchGuess.guesses.slice(-1)[0] : null
}


function matchGuessToPoints2(matchGuess, match) {
    const latestGuess = matchGuess.guesses.slice(-1)[0]
    const pts = guessScoresToPoints(match.currentScore.home, match.currentScore.away, latestGuess.homeScore, latestGuess.awayScore, match.tournamentPhase.matchGuessPoints)
    return pts
}

function guessScoresToPoints(realHome, realAway, guessHome, guessAway, matchGuessPoints) {
    if (matchGuessPoints.correct !== null && realHome === guessHome && realAway === guessAway)
        return matchGuessPoints.correct
    if (matchGuessPoints.difference !== null && realHome - realAway === guessHome - guessAway)
        return matchGuessPoints.difference
    if (matchGuessPoints.tendency !== null && (
        (realHome > realAway && guessHome > guessAway) ||
        (realHome < realAway && guessHome < guessAway) ||
        (realHome === realAway && guessHome === guessAway))
    )
        return matchGuessPoints.tendency
    return matchGuessPoints.wrong ?? 0
}