import { useContext } from "react";
import { TournamentsContext } from "../context/TournamentsContext.js";

export default function useTournamentsContext() {
    const context = useContext(TournamentsContext)

    if (!context) {
        throw Error("useTournamentsContext must be used inside a TournamentsContextProvider")
    }

    return context
}