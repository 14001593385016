import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import useAuthContext from "./hooks/useAuthContext.js";
import Home from "./pages/Home.js";
import TippspielNavbar from "./components/navbar/TippspielNavbar.js";
import Tournament from "./pages/Tournament.js";
import Match from "./pages/Match.js";
import Register from "./pages/Register.js";
import Login from "./pages/Login.js";
import User from "./pages/User.js";
import Footer from "./components/footer/Footer.js";
import Imprint from "./pages/Imprint.js";
import DataUsage from "./pages/DataUsage.js";
import NewTournament from "./pages/NewTournament.js";
import CookieConsentBanner from "./components/util/CookieConsentBanner.js";
import JoinTournamentForm from "./components/tournament/JoinTournamentForm.js";
import EditTournament from "./pages/EditTournament.js";
import Container from "react-bootstrap/Container";
import StatsPage from "./pages/StatsPage";
import EditGuesses from "./pages/EditGuesses";

function App() {
    // todo: enable middle mouse button -> do not log user out; go to correct page
    const { authUser } = useAuthContext()

    return <BrowserRouter>
        <TippspielNavbar/>
        <Container className="content my-3 p-0 ms-0 ms-sm-auto">
            <CookieConsentBanner/>
            <Routes>
                <Route path="/" element={authUser ? <Home/> : <Navigate to="/anmelden"/>}/>
                <Route path="/turnierverlauf" element={authUser ? <Tournament/> : <Navigate to="/anmelden"/>}/>
                <Route path="/statistiken" element={authUser ? <StatsPage/> : <Navigate to="/anmelden"/>}/>
                <Route path="/tippspiel-erstellen"
                       element={authUser ? (authUser.isAdmin ? <NewTournament/> : <Navigate to="/"/>) :
                           <Navigate to="/anmelden"/>}/>
                <Route path="/tippspiel-bearbeiten"
                       element={authUser ? (authUser.isAdmin ? <EditTournament/> : <Navigate to="/"/>) :
                           <Navigate to="/anmelden"/>}/>
                <Route path="/tipps-bearbeiten"
                       element={authUser ? (authUser.isAdmin ? <EditGuesses/> : <Navigate to="/"/>) :
                           <Navigate to="/anmelden"/>}/>

                <Route path="/tippspiel-beitreten" element={authUser ? <JoinTournamentForm/> : <Navigate to="/anmelden"/>}/>

                <Route path="/spiel" element={authUser ? <Match/> : <Navigate to="/anmelden"/>}/>
                <Route path="/spieler/*" element={authUser ? <User/> : <Navigate to="/anmelden"/>}/>

                <Route path="/anmelden" element={!authUser ? <Login/> : <Navigate to="/"/>}/>
                <Route path="/registrieren" element={!authUser ? <Register/> : <Navigate to="/"/>}/>

                <Route path="/impressum" element={<Imprint/>}/>
                <Route path="/datenschutzerklärung" element={<DataUsage/>}/>
            </Routes>
        </Container>
        <Footer/>
    </BrowserRouter>
}

export default App;
